import { FdSelectConfig } from './fd-select/fd-select.component';
import { FdInputConfig } from './fd-input/fd-input.component';
import { faDownload, faEdit, faExclamationTriangle, faFile, faUpload } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FdLabelConfig } from './fd-label/fd-label.component';
import { FdCurrencyInputConfig } from './fd-currency-input/fd-currency-input.component';
import { FdRadioButtonConfig } from './fd-radio-button/fd-radio-button.component';
import { FdDatepickerConfig } from './fd-datepicker/fd-datepicker.component';
import { FD_DATE_FORMATS } from './fd-datepicker/date-adapter';
import { FdOptionPickerConfig } from './fd-option-picker/fd-option-picker.component';
const ɵ0 = FD_DATE_FORMATS;
export class FdFormComponentsModule {
    constructor() {
        library.add(faExclamationTriangle, faDownload, faUpload, faEdit, faFile);
    }
}
export { ɵ0 };
