import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PhonePipe} from './phone.pipe';
import {CpfCnpjPipe} from './cpf-cnpj.pipe';


@NgModule({
  declarations: [
    PhonePipe,
    CpfCnpjPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  exports: [
    PhonePipe,
    CpfCnpjPipe
  ]
})
export class PipesModule {
  constructor() {
  }
}

