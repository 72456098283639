const BLOCK_2: RegExp[] = [/[0-9]/, /[0-9]/];
const BLOCK_3: RegExp[] = [/[0-9]/, /[0-9]/, /[0-9]/];
const BLOCK_4: RegExp[] = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

const CPF_LENGTH: number = 11;
const AGENCY_NUMBER_LENGHT : number = 6;

export const cpfMask: (RegExp | string)[] = [
  ...BLOCK_3, '.', ...BLOCK_3, '.', ...BLOCK_3, '-', ...BLOCK_2
];

export const cnpjMask: (RegExp | string)[] = [
  ...BLOCK_2, '.', ...BLOCK_3, '.', ...BLOCK_3, '/', ...BLOCK_4, '-', ...BLOCK_2
];

export function cpfCnpjMask(userInput: string): (RegExp | string)[] {
  const numbers: string = (userInput || '').replace(/\D/g, '');
  return numbers.length <= CPF_LENGTH ? cpfMask : cnpjMask;
}

