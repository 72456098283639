import { NativeDateAdapter } from "@angular/material";
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
    providedIn: 'root'
})
export class FdDateAdapter extends NativeDateAdapter {

    constructor() {
        super('pt-BR', new Platform());
    }

    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
          const str = value.split('/');
          if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
            return null;
          }
          return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
      }

}

export const FD_DATE_FORMATS =
{
    parse: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { month: 'numeric', year: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    }
}