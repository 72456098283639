/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/fd-form-components/fd-button/fd-button.component.ngfactory";
import * as i4 from "../../../shared/fd-form-components/fd-button/fd-button.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../shared/pipes/cpf-cnpj.pipe";
import * as i7 from "../../../shared/pipes/phone.pipe";
import * as i8 from "./user-details.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../services/user.service";
import * as i11 from "../../../shared/service/loading.service";
var styles_UserDetailsComponent = [i0.styles];
var RenderType_UserDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailsComponent, data: {} });
export { RenderType_UserDetailsComponent as RenderType_UserDetailsComponent };
function View_UserDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome: "])), (_l()(), i1.ɵted(3, null, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userData.name; _ck(_v, 3, 0, currVal_0); }); }
function View_UserDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CPF: "])), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _co.userData.cpfCnpj)); _ck(_v, 3, 0, currVal_0); }); }
function View_UserDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Celular: "])), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 1), _co.userData.mobileNumber)); _ck(_v, 3, 0, currVal_0); }); }
function View_UserDetailsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email: "])), (_l()(), i1.ɵted(3, null, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userData.email; _ck(_v, 3, 0, currVal_0); }); }
function View_UserDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "proposal-detail-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dados do usu\u00E1rio"])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "proposal-detail"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "fd-button", [["fdSecondaryLarge", ""], ["mat-button", ""]], null, [[null, "trigger"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("trigger" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FdButtonComponent_0, i3.RenderType_FdButtonComponent)), i1.ɵdid(13, 114688, null, 0, i4.FdButtonComponent, [i5.DomSanitizer, [8, null], [8, null], [8, null], [8, null], [8, null], [8, null], [8, null], [8, ""]], { content: [0, "content"] }, { trigger: "trigger" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userData.name; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.userData.cpfCnpj; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.userData.mobileNumber; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.userData.email; _ck(_v, 11, 0, currVal_3); var currVal_4 = "Sair"; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_UserDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.CpfCnpjPipe, []), i1.ɵpid(0, i7.PhonePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userData; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_UserDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-details", [], null, null, null, View_UserDetailsComponent_0, RenderType_UserDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i8.UserDetailsComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA, i10.UserService, i11.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDetailsComponentNgFactory = i1.ɵccf("app-user-details", i8.UserDetailsComponent, View_UserDetailsComponent_Host_0, {}, {}, []);
export { UserDetailsComponentNgFactory as UserDetailsComponentNgFactory };
