import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {ProposalListModel} from '../../models/proposal-list.model';
import {SortTable} from '../../../shared/models/pageable/pageable.model';
import {ProposalStatusEnum} from '../../../shared/enums/proposal-status.enum';

@Component({
  selector: 'app-list-proposal',
  templateUrl: './list-proposal.component.html',
  styleUrls: ['./list-proposal.component.scss']
})
export class ListProposalComponent implements OnInit, OnChanges {

  displayedColumns = ['id', 'document', 'fantasyName', 'socialReason', 'idProposalStatus', 'createdDate', 'vendorName', 'actions'];

  hasValue = false;
  previousSizeItems = 0;
  defaultSizeItems = 50;

  @Input() dataSource: MatTableDataSource<ProposalListModel>;

  @Input() sizeItems: number;
  @Input() actionsTabActive: boolean;

  @Input() totalPages: 0;

  @ViewChild('binding', { static: false }) binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() changeSortPage = new EventEmitter<SortTable>();

  @Output() retryProposal = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    this.hasValue = this.dataSource.data.length > 0;
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  changeSort(event) {
    this.changeSortPage.emit(event);
  }

  getStatusById(idStatus: ProposalStatusEnum) {
    if (this.statusErrorProcessing().includes(idStatus)) {
      return 'Erro';
    }

    if (this.statusPartiallyProcessing().includes(idStatus)) {
      return 'Parcialmente Processado';
    }

    if (idStatus === ProposalStatusEnum.PROCESSED) {
      return 'Processado';
    }

    if (this.statusPendingProcessing().includes(idStatus)) {
      return 'Em Processamento';
    }

  }

  retry(proposal: ProposalListModel) {
    this.retryProposal.emit(proposal.id);
  }

  hiddenButton(proposal: ProposalListModel) {
    return !(this.statusPartiallyProcessing().includes(proposal.idProposalStatus) ||
              this.statusErrorProcessing().includes(proposal.idProposalStatus));
  }

  statusPartiallyProcessing(): ProposalStatusEnum[] {
    return [ProposalStatusEnum.PARTIALLY_PROCESSED_BASE_PRODUCTS, ProposalStatusEnum.PARTIALLY_PROCESSED_BANK_WORKS];
  }

  statusErrorProcessing(): ProposalStatusEnum[] {
    return [ProposalStatusEnum.ERROR_BASE_PRODUCTS, ProposalStatusEnum.ERROR_BANK_WORKS];
  }
  statusPendingProcessing(): ProposalStatusEnum[] {
    return [ProposalStatusEnum.PENDING_BANK_WORKS, ProposalStatusEnum.PENDING_BASE_PRODUCTS];
  }


}



