import {Component, OnInit} from '@angular/core';
import {NavBarItems} from './header.items';
import {AuthService} from 'src/app/shared/service/auth.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navItems = NavBarItems;

  name: string;
  conciliatorName: string;

  constructor(
    protected authService: AuthService,

  ) { }

  ngOnInit() {
    this.name = this.authService.getUserName();
    this.conciliatorName = this.authService.getConciliatorName();
  }

  logout() {
    this.authService.logout();
  }
}
