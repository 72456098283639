import { BehaviorSubject } from 'rxjs';
import { AuthServiceModel } from 'src/app/shared/models/response/auth-service.model';
import { LoginModel } from 'src/app/shared/models/login.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./token.service";
export class AuthService {
    constructor(router, dialog, activatedRoute, tokenService) {
        this.router = router;
        this.dialog = dialog;
        this.activatedRoute = activatedRoute;
        this.tokenService = tokenService;
        this.CONCILIATOR_TOKEN_KEY = 'authServiceToken';
        this.CONCILIATOR_ROLES_KEY = 'authServiceRoles';
        this.CONCILIATOR_USERNAME_KEY = 'authServiceName';
        this.CONCILIATOR_USER_DATA_KEY = 'authServiceUserData';
        this.CONCILIATOR_NAME = 'authServiceConciliatorName';
        this.CONCILIATOR_ID = 'authServiceConciliatorID';
        this.loggedIn = false;
        this._authService = new AuthServiceModel();
        this.authService$ = new BehaviorSubject(this._authService);
        this._userData = new LoginModel();
        this.userData$ = new BehaviorSubject(this._userData);
    }
    getStorageData() {
        const data = new AuthServiceModel();
        const strUser = sessionStorage.getItem(this.CONCILIATOR_USERNAME_KEY);
        const strToken = sessionStorage.getItem(this.CONCILIATOR_TOKEN_KEY);
        data.nome = strUser;
        data.sessionToken = strToken;
        try {
            if (data != null && data.sessionToken.trim().length > 0 && data.nome.trim().length > 0) {
                return data;
            }
        }
        catch (_a) {
            return null;
        }
    }
    savedUserName() {
        return sessionStorage.getItem(this.CONCILIATOR_USERNAME_KEY);
    }
    savedConciliatorName() {
        return sessionStorage.getItem(this.CONCILIATOR_NAME);
    }
    savedConciliatorId() {
        return Number(sessionStorage.getItem(this.CONCILIATOR_ID));
    }
    getUserRoles() {
        const storageItem = sessionStorage.getItem(this.CONCILIATOR_ROLES_KEY);
        if (storageItem) {
            return JSON.parse(storageItem);
        }
    }
    isUserInRoles(roleList) {
        return this.getUserRoles().some(userRole => roleList.includes(userRole));
    }
    isThereUserRoles() {
        return this.getUserRoles().length > 0;
    }
    getUserName() {
        if (!this._authService.nome) {
            this._authService.nome = this.savedUserName();
        }
        return this._authService.nome;
    }
    getConciliatorName() {
        if (!this._authService.conciliatorName) {
            this._authService.conciliatorName = this.savedConciliatorName();
        }
        return this._authService.conciliatorName;
    }
    getConciliatorId() {
        if (!this._authService.conciliatorId) {
            this._authService.conciliatorId = this.savedConciliatorId();
        }
        return this._authService.conciliatorId;
    }
    setAuthService(authService) {
        if (authService.fgBloqueado === 'N') {
            this._authService = authService;
            const decodedToken = this.tokenService.getDecodedAccessToken(authService.sessionToken);
            if (!decodedToken) {
                return false;
            }
            this.setSessionInformation({ name: authService.nome, sessionToken: authService.sessionToken, roles: authService.roles,
                conciliator: authService.conciliatorName, conciliatorId: authService.conciliatorId }, decodedToken);
            return true;
        }
        return false;
    }
    getUserData() {
        const sessionItem = sessionStorage.getItem(this.CONCILIATOR_USER_DATA_KEY);
        if (!sessionItem) {
            return;
        }
        return JSON.parse(sessionItem);
    }
    setSessionInformation(sessionInfo, tokenInfo) {
        sessionStorage.removeItem(this.CONCILIATOR_TOKEN_KEY);
        sessionStorage.removeItem(this.CONCILIATOR_USERNAME_KEY);
        sessionStorage.removeItem(this.CONCILIATOR_ROLES_KEY);
        sessionStorage.removeItem(this.CONCILIATOR_USER_DATA_KEY);
        sessionStorage.removeItem(this.CONCILIATOR_NAME);
        sessionStorage.removeItem(this.CONCILIATOR_ID);
        sessionStorage.setItem(this.CONCILIATOR_TOKEN_KEY, sessionInfo.sessionToken);
        sessionStorage.setItem(this.CONCILIATOR_USERNAME_KEY, sessionInfo.name);
        sessionStorage.setItem(this.CONCILIATOR_ROLES_KEY, JSON.stringify(sessionInfo.roles));
        sessionStorage.setItem(this.CONCILIATOR_USER_DATA_KEY, JSON.stringify(tokenInfo));
        sessionStorage.setItem(this.CONCILIATOR_NAME, sessionInfo.conciliator);
        sessionStorage.setItem(this.CONCILIATOR_ID, sessionInfo.conciliatorId.toString());
    }
    clear() {
        sessionStorage.clear();
        this.loggedIn = false;
    }
    logout() {
        this.clear();
        this.router.navigate(['/login']);
    }
    expiredToken() {
        this.logout();
    }
    isLoggedIn() {
        if (this.loggedIn) {
            return true;
        }
        else {
            this._authService = this.getStorageData();
            if (this.checkUser(this._authService)) {
                this.loggedIn = true;
                return true;
            }
            else {
                this.clear();
                return false;
            }
        }
    }
    checkUser(authService) {
        return authService && authService.sessionToken != null;
    }
    loginSuccess(loginData) {
        if (this.checkUser(loginData)) {
            this.setAuthService(loginData);
            this.loggedIn = true;
        }
        else {
            this.loggedIn = false;
        }
        return this.loggedIn;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i3.TokenService)); }, token: AuthService, providedIn: "root" });
export class SessionInformation {
}
export class TokenInfoModel {
}
