<div *ngIf="userData">
      <h4 class="proposal-detail-title">Dados do usuário</h4>
      <div class="proposal-detail">
        <span *ngIf="userData.name">
          <strong>Nome: </strong> {{userData.name}}<br>
        </span>
        <span *ngIf="userData.cpfCnpj">
          <strong>CPF: </strong> {{userData.cpfCnpj | cpfCnpj}}<br>
        </span>
        <span *ngIf="userData.mobileNumber">
          <strong>Celular: </strong> {{userData.mobileNumber | phone}}<br>
        </span>
        <span *ngIf="userData.email">
          <strong>Email: </strong> {{userData.email}}<br>
        </span>
        <fd-button mat-button fdSecondaryLarge [content]="'Sair'" (trigger)="close()" > </fd-button>
      </div>
</div>
