import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Messages } from '../messages/messages';
import { DialogService } from './dialog.service';
import { FileService } from './file.service';
import {BaseResponseModel} from '../models/base-response.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private dialogService: DialogService, private fileService: FileService) { }

  handleXHRError(err: HttpErrorResponse, genericErrorMessage: Messages, callbackFn?: () => any) {
    console.error(err);
    const serializedError = err.error ? err.error as BaseResponseModel<any> : null;

    if (serializedError && serializedError.mensagem) {
      if (callbackFn) {
        this.dialogService.openDialogWithMessage(serializedError.mensagem, () => callbackFn());
        return;
      }
      this.dialogService.openDialogWithMessage(serializedError.mensagem);
    } else {
      if (callbackFn) {
        this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
        return;
      }
      this.dialogService.openDialog(genericErrorMessage);
    }
  }

  handleXHRErrorDownload(err: HttpErrorResponse, genericErrorMessage: Messages, callbackFn?: () => any) {
    const serializedError = err.error ? err.error as Blob : null;

    let fr = new FileReader();
    fr.readAsText(serializedError);

    fr.addEventListener('loadend', (e) => {

      const text: BaseResponseModel<any> = JSON.parse(fr.result.toString());
      if (text.codigoRetorno !== 0 && text.mensagem) {
        if (callbackFn) {
          this.dialogService.openDialogWithMessage(text.mensagem, () => callbackFn());
          return;
        }
        this.dialogService.openDialogWithMessage(text.mensagem);
        return;
      } else {
        if (callbackFn) {
          this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
          return;
        }
        this.dialogService.openDialog(genericErrorMessage);
      }
      return;
    });
  }
}
