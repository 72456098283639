/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./side-nav.component";
import * as i8 from "../../../../shared/service/auth.service";
var styles_SideNavComponent = [i0.styles];
var RenderType_SideNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavComponent, data: {} });
export { RenderType_SideNavComponent as RenderType_SideNavComponent };
function View_SideNavComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["mat-tab-link", ""], ["routerLinkActive", "activeSubItens"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(8, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 6, 0, true); var currVal_4 = "activeSubItens"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵnov(_v, 8).renderedIconHTML; _ck(_v, 7, 0, currVal_5); var currVal_7 = _v.parent.context.$implicit.text; _ck(_v, 9, 0, currVal_7); }); }
function View_SideNavComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUserInRoles(_v.context.$implicit.allowedRoles); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SideNavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.options; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SideNavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "fd-accreditation-side-nav__nav-item"], ["mat-tab-link", ""], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(8, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "sub-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_3)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 6, 0, true); var currVal_4 = "active"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 8, 0, currVal_6); var currVal_8 = _v.parent.context.$implicit.hasOptions; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵnov(_v, 8).renderedIconHTML; _ck(_v, 7, 0, currVal_5); var currVal_7 = _v.parent.context.$implicit.text; _ck(_v, 9, 0, currVal_7); }); }
function View_SideNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "fd-accreditation-side-nav__nav-li"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUserInRoles(_v.context.$implicit.allowedRoles); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SideNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nav", [["class", "fd-accreditation-side-nav"], ["mat-tab-nav-bar", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SideNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "side-nav", [], null, null, null, View_SideNavComponent_0, RenderType_SideNavComponent)), i1.ɵdid(1, 49152, null, 0, i7.SideNavComponent, [i8.AuthService], null, null)], null, null); }
var SideNavComponentNgFactory = i1.ɵccf("side-nav", i7.SideNavComponent, View_SideNavComponent_Host_0, {}, {}, []);
export { SideNavComponentNgFactory as SideNavComponentNgFactory };
