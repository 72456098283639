/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./core.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/components/header/header.component.ngfactory";
import * as i3 from "./layout/components/header/header.component";
import * as i4 from "../shared/service/auth.service";
import * as i5 from "./layout/components/side-nav/side-nav.component.ngfactory";
import * as i6 from "./layout/components/side-nav/side-nav.component";
import * as i7 from "@angular/router";
import * as i8 from "./core.component";
var styles_CoreComponent = [i0.styles];
var RenderType_CoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CoreComponent, data: {} });
export { RenderType_CoreComponent as RenderType_CoreComponent };
export function View_CoreComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "side-nav", [], null, null, null, i5.View_SideNavComponent_0, i5.RenderType_SideNavComponent)), i1.ɵdid(3, 49152, null, 0, i6.SideNavComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "container fd-accreditation-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 6, 0); }, null); }
export function View_CoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-core", [], null, null, null, View_CoreComponent_0, RenderType_CoreComponent)), i1.ɵdid(1, 114688, null, 0, i8.CoreComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CoreComponentNgFactory = i1.ɵccf("app-core", i8.CoreComponent, View_CoreComponent_Host_0, {}, {}, []);
export { CoreComponentNgFactory as CoreComponentNgFactory };
