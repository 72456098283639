<h3>Usuários</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasWriteAccess">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addUser()">
        <div class="d-flex  justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Novo usuário
        </div>
      </button>
    </div>
  </div>
</div>

<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="1%" class="filter-container">
      <div fxFlex="70">
        <fd-input [parentForm]="formGroup" [field]="fields.cpfCnpj">
        </fd-input>
      </div>
      <div fxFlex="10" fxFlexAlign="center">
        <fd-button mat-flat-button [content]="'Buscar'" (trigger)="findUsers()"></fd-button>
      </div>
    </div>
  </div>
</form>

<div class='list'>
  <app-list-users [dataSource]="dataSource"
                  [sizeItems]="sizeItems"
                  [totalPages]="totalPages"
                  (changePage)="changePage($event)"
                  (changeSortPage)="changeSortPage($event)"
                  (disableItem)="inactivateUser($event)"
                  (enableItem)="activateUser($event)"
                  (editItem)="editUser($event)"
                  (itemDetails)="viewUserDetails($event)">
  </app-list-users>
</div>
