import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Messages} from 'src/app/shared/messages/messages';
import {DialogService} from 'src/app/shared/service/dialog.service';
import {UserTypeEnum} from 'src/app/shared/enums/user-type.enum';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {CpfCnpjPipe} from 'src/app/shared/pipes/cpf-cnpj.pipe';
import {SortTable} from '../../../shared/models/pageable/pageable.model';
import {UserListModel} from '../../models/user.model';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
  providers: [CpfCnpjPipe]
})
export class ListUsersComponent implements OnInit, OnChanges {

  displayedColumns = ['cpfCnpj', 'name', 'profile.description', 'mobileNumber', 'email', 'options_edit'];

  hasValue = false;
  previousSizeItems = 0;
  defaultSizeItems = 50;

  @Input() dataSource: MatTableDataSource<UserListModel>;

  @Input() sizeItems: number;
  @Input() actionsTabActive: boolean;

  @Input() totalPages: 0;

  @ViewChild('binding', { static: false }) binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() changeSortPage = new EventEmitter<SortTable>();


  @Output() disableItem = new EventEmitter<string>();
  @Output() enableItem = new EventEmitter<string>();
  @Output() itemDetails = new EventEmitter<string>();
  @Output() editItem = new EventEmitter<string>();



  constructor(
    private dialogService: DialogService,
  ) { }

  get hasConciliatorAccess() {
    return true;
  }

  canEdit(type: UserTypeEnum) {
    if (type === UserTypeEnum.CONCILIATOR) {
      return this.hasConciliatorAccess;
    }
  }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    this.hasValue = this.dataSource.data.length > 0;
  }

  disable(userModel: UserListModel) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DISABLE_ACTION, () => this.disableItem.emit(userModel.cpfCnpj));
  }

  enable(userModel: UserListModel) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION, () => this.enableItem.emit(userModel.cpfCnpj));
  }

  detail(userModel: UserListModel) {
    this.itemDetails.emit(userModel.cpfCnpj);
  }

  edit(userModel: UserListModel) {
    this.editItem.emit(userModel.cpfCnpj);
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  isEnabledDescription(status: string) {
    return status === 'A' ? 'Habilitado' : 'Desabilitado';
  }

  isEnabled(status: UserListModel) {
    return status.status === 'A';
  }

  changeSort(event) {
    this.changeSortPage.emit(event);
  }
}
