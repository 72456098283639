
const accreditation = {
    text: 'Credenciamento',
    link: '#'
};

const administrative = {
    text: 'Área Administrativa ',
    link: '#'
};

export const NavBarItems = [
    accreditation,
    administrative
];
