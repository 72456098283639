<button
  class="fd-button"
  [ngClass]="{
    'fd-button--primary': primary,
    'fd-button--secondary': secondary,
    'fd-button--tertiary': tertiary,
    'fd-button--smallButton': smallButton,
    'fd-button--fatButton': fatButton,
    'fd-button--primaryLarge': primaryLarge,
    'fd-button--primaryMedium': primaryMedium,
    'fd-button--secondaryLarge': secondaryLarge,
    'fd-button--disabled': disabled,
    'fd-button--export' : export
  }"
  [disabled]="disabled || null"
  (click)="handleTrigger($event)">
  <div class="d-flex  justify-content-center" [innerHTML]="safeContent">
  </div>
</button>
