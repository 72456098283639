import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { FileContentTypeEnum } from '../enums/file-content-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  saveFile(blobBuffer: any, type: FileContentTypeEnum, fileName: string): void {
    const fileStream: Blob = new Blob([blobBuffer], { type });
    FileSaver.saveAs(fileStream, fileName);
  }
}
