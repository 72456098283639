<mat-form-field class="fd-input__container" color="accent">
  <div [formGroup]="parentForm">
    <input matInput [ngClass]="{'upper-case': field?.transform === 'UPPERCASE'}" [formControlName]="field?.controlName"
      class="fd-input__field" [placeholder]="field?.label"
      [textMask]="{ mask: field?.mask || false, guide: true, placeholderChar: '\u2000' }" [id]="field?.controlName"
      [maxlength]="field?.maxLength" [required]="required || null" aria-label="Area code" [type]="field?.type || 'text'"
      autocomplete="off" tabindex="0" (blur)="handleBlur($event)" (input)="handleChange($event)">
  </div>
  <mat-hint class="fd-input__hint">{{field?.hint}}</mat-hint>
  <mat-error *ngIf="hasError">
    <div *ngFor="let errorMessage of errorMessages">{{errorMessage}}</div>
  </mat-error>
</mat-form-field>
