export class PageableModel {
  page: number;
  size: number;
  sort: string;


  constructor(page: number, size: number) {
    this.page = page ? page : 0;
    this.size = size;
  }

  setSort(sortOrder: string, sortField: string) {
    if (sortOrder) {
      this.sort = sortField + ',' + sortOrder.toUpperCase();
    } else {
      this.sort = undefined;
    }
  }

    setSize(size: number) {
      this.size = (size ? size : 5);
    }

    setPage( page: number) {
    if (page && page === 0) {
      this.page = 0;
    } else {
      this.page = page / this.size;
    }
  }

}

export class SortTable {
  active: string;
  direction: string;
}
