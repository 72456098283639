import { Component, OnInit, Inject, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-fd-alert',
  templateUrl: './fd-alert.component.html',
  styleUrls: ['./fd-alert.component.scss']
})
export class FdAlertComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<FdAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData) { }

  ngOnInit() {
  }

  handleClick(): void {
    if (this.data.callbackFn) {
      this.data.callbackFn();
    }
    this.dialogRef.close();
  }
}

export interface AlertDialogData {
  title: string;
  description: string;
  callbackFn: () => any;
}

export enum ModalDefinitions {
  DEFAULT_MODAL_WIDTH = '450px'
}
