<h3>Monitoramento de propostas</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasCreateProposal()">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="createProposal()">
        <div class="d-flex justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Nova Proposta
        </div>
      </button>
    </div>
  </div>
</div>
<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="1%" class="fd-proposal-search__filter-field-container">
      <div class="fd-proposal-search__filter-field" fxFlex="100">
        <fd-datepicker [parentForm]="formGroup" [field]="fields.startDate"></fd-datepicker>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlex="100">
        <fd-datepicker [parentForm]="formGroup" [field]="fields.endDate"></fd-datepicker>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlex="100">
        <fd-select [parentForm]="formGroup" [field]="fields.proposalsState">
        </fd-select>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlex="100">
        <fd-select (loadItemsFn)="loadInstitutions($event)" [parentForm]="formGroup"
                   [field]="fields.institutionNumber" (selectionChange)="selectedInstitution()">
        </fd-select>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlex="100">
        <fd-input [parentForm]="formGroup" [field]="fields.cpfCnpj">
        </fd-input>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
        <fd-button [content]="'Buscar'" (trigger)="searchProposals()"></fd-button>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
        <fd-button fdTertiary [content]="'Limpar'" (trigger)="clearFilters()"></fd-button>
      </div>
      <div class="fd-proposal-search__filter-field" fxFlexAlign="center">
        <fd-button fdExport [content]="'Exportar'" (trigger)="exportGrid()"></fd-button>
      </div>
    </div>
  </div>
</form>

<div class='list-proposal'>
  <app-list-proposal
                     [dataSource]="dataSource"
                     [sizeItems]="sizeItems"
                     [totalPages]="totalPages"
                     (changePage)="changePage($event)"
                     (changeSortPage)="changeSortPage($event)"
                     (retryProposal)="retryProposal($event)"
                     [actionsTabActive]="false"
  >
  </app-list-proposal>
</div>
