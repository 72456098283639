import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchProposalModel } from 'src/app/proposal/models/search-proposal.model';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/service/auth.service';
import {ProposalListModel} from '../models/proposal-list.model';
import {ProposalModel} from '../models/proposal.model';
import {BaseResponseModel} from '../../shared/models/base-response.model';
import {Utils} from '../../shared/utils/utils';
import { ProposalReportModel } from '../models/proposal-report.model';
import {PageableModel} from '../../shared/models/pageable/pageable.model';
import {PageableResponseModel} from '../../shared/models/pageable/pageable-response.model';

@Injectable({ providedIn: 'root' })
export class ProposalService {
  constructor(
    private httpClient: HttpClient,
    protected authService: AuthService
  ) {}

  save(proposal: ProposalModel): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrls.proposal}`, proposal);
  }

  searchProposals(filter: SearchProposalModel, pageable: PageableModel): Observable<BaseResponseModel<PageableResponseModel<ProposalListModel[]>>> {
    const httpParams = Utils.mountHttpParams(filter, pageable);
    return this.httpClient.get<BaseResponseModel<PageableResponseModel<ProposalListModel[]>>>(`${environment.apiUrls.proposal}`,
      {params: httpParams});
  }

  excelReport(filter: SearchProposalModel, pageable: PageableModel): Observable<BaseResponseModel<ProposalReportModel[]>> {
    const httpParams = Utils.mountHttpParams(filter, pageable);
    return this.httpClient.get<BaseResponseModel<ProposalReportModel[]>>(`${environment.apiUrls.proposal}/excel-report`,
      {params: httpParams});
  }

  existsDocumentByInstitution(institutionId: string, document: string): Observable<BaseResponseModel<string>> {
    return this.httpClient.get<BaseResponseModel<string>>(`${environment.apiUrls.merchants}/${institutionId}/${document}/exists`);
  }

  retryProposal(idProposal: number): Observable<BaseResponseModel<boolean>> {
    return this.httpClient.post<BaseResponseModel<boolean>>(`${environment.apiUrls.proposal}/${idProposal}/retry`, {});
  }
}


