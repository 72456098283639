import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingActive: boolean;
  private loadingActive$: BehaviorSubject<boolean>;

  constructor() {
    this.loadingActive = false;
    this.loadingActive$ = new BehaviorSubject(this.loadingActive);
  }

  public getLoadingStatusObservable(): Observable<boolean> {
    return this.loadingActive$;
  }

  public isLoadingActive(): boolean {
    return this.loadingActive;
  }

  public hide() {
    this.loadingActive = false;
    this.loadingActive$.next(this.loadingActive);
  }

  public show() {
    this.loadingActive = true;
    this.loadingActive$.next(this.loadingActive);
  }
}
