import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FdFieldConfigs} from '../shared/fd-form-components/fd-form-components.module';
import {HierarchyService} from '../shared/service/hierarchy.service';
import {LoadingService} from '../shared/service/loading.service';
import {DatePipe} from '@angular/common';
import {MatDialog, MatTableDataSource, PageEvent} from '@angular/material';
import {DialogService} from '../shared/service/dialog.service';
import {Messages} from '../shared/messages/messages';
import {UserService} from './services/user.service';
import {UserDetailsComponent} from './components/user-details/user-details.component';
import {AuthService} from '../shared/service/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import {cpfCnpjMask} from '../shared/masks/document-masks';
import {ErrorService} from '../shared/service/error.service';
import {FormUserComponent} from './components/form-user/form-user.component';
import {UserListModel} from './models/user.model';
import {PageableModel, SortTable} from '../shared/models/pageable/pageable.model';
import {UserFilterModel} from './models/user-filter.model';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AdminRolesEnum} from '../shared/enums/admin-roles.enum';

@Component({
  selector: 'app-users',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  dataSource: MatTableDataSource<UserListModel> = new MatTableDataSource<UserListModel>();
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  pageNumber = 0;
  sizeItems = 50;
  totalItens;
  totalPages = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private hierarchyService: HierarchyService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loadingService: LoadingService,
    private userService: UserService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.startForms();

    if (!this.hasWriteAccess) {
      this.findUsers();
    }
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR_USER_MASTER]);
  }

  addUser() {
    const dialogRef = this.dialog.open(FormUserComponent, {
      width: '40%',
      height: '85%',
      disableClose: true,
      data: {
        serviceContract: this.formGroup.value.serviceContract,
        institutionId: this.formGroup.value.institutionNumber
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => this.findUsers(), 200);
    });
  }

  setUserStatus(cpfCnpj: string, isEnabled: boolean) {
    this.loadingService.show();
    this.userService.setUserStatus(cpfCnpj, {status : isEnabled ? 'A' : 'I'})
      .pipe(finalize( () => this.loadingService.hide()))
      .subscribe(
      () => this.dialogService.openDialog(Messages.EDIT_SUCCESS, () => this.findUsers()),
      (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); }
    );
  }

  inactivateUser(cpfCnpj: string) {
    this.setUserStatus(cpfCnpj, false);
  }

  activateUser(cpfCnpj: string) {
    this.setUserStatus(cpfCnpj, true);
  }

  editUser(cpfCnpj: string) {
    const dialogRef = this.dialog.open(FormUserComponent, {
      width: '40%',
      height: '95%',
      data : cpfCnpj
    });
    dialogRef.afterClosed().subscribe(() => {
      // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
      setTimeout(() => this.findUsers(), 200);
    });
  }

  viewUserDetails(cpfCnpj: string) {
    this.dialog.open(UserDetailsComponent, {
      width: '35%',
      height: '65%',
      data: cpfCnpj
    });
  }

  buildFilter() {
    const filter = new UserFilterModel();
    filter.cpfCnpj = this.formGroup.value.cpfCnpj ? this.formGroup.value.cpfCnpj.match(/\d/g).join('') : undefined;
    return filter;
  }

  findUsers(event?: SortTable) {
    const filter = this.buildFilter();
    const pageable = new PageableModel(this.pageNumber, this.sizeItems);
    if (event) {
      pageable.setSort(event.direction, event.active);
    }

    this.loadingService.show();
    this.userService.getAllUsers(filter, pageable)
      .pipe(finalize( () => this.loadingService.hide()))
      .subscribe(res => {
          const item = res.conteudo;
          this.dataSource = new MatTableDataSource(res.conteudo.content);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totalPages = item.totalElements;
          this.pageNumber = item.number;
      }, err => console.error(err));
  }

  // FORMS
  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      cpfCnpj: ['']
    });

    this.fields = {
      cpfCnpj: {
        label: 'CPF/CNPJ',
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF/CNPJ',
          invalid: 'CPF/CNPJ inválido'
        }
      }
    };
  }

  changePage(event: PageEvent) {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findUsers();
  }

  changeSortPage(event: SortTable): void {
    this.findUsers(event);
  }
}
