import {Routes} from 'src/app/shared/routing/routes';
import {AdminRolesEnum} from 'src/app/shared/enums/admin-roles.enum';

const proposal = {
  text: 'Monitoramento de propostas',
  link: Routes.LIST_PROPOSALS,
  allowedRoles: [AdminRolesEnum.CONCILIATOR_PROPOSAL, AdminRolesEnum.CONCILIATOR_USER_MASTER],
  icon: 'bars',
  hasOptions: true,
  optionsName: '',
  options: []
};

const users = {
  text: 'Usuários',
  link: Routes.USERS,
  allowedRoles: [AdminRolesEnum.CONCILIATOR_USER_MASTER],
  icon: 'users',
  hasOptions: true,
  optionsName: '',
  options: []
};

export const SideNavItems = [
  proposal,
  users
];
