import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginModel} from 'src/app/shared/models/login.model';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AuthServiceModel} from 'src/app/shared/models/response/auth-service.model';
import {BaseResponseModel} from '../../shared/models/base-response.model';


@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  login(data: LoginModel): Observable<BaseResponseModel<AuthServiceModel>> {
    return this.http.post<BaseResponseModel<AuthServiceModel>>(`${environment.apiUrls.authService}`, data);
  }

}
