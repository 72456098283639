import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthServiceModel} from 'src/app/shared/models/response/auth-service.model';
import {LoginModel} from 'src/app/shared/models/login.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {TokenService} from './token.service';
import {AdminRolesEnum} from '../enums/admin-roles.enum';
import {UserTypeEnum} from '../enums/user-type.enum';

@Injectable({ providedIn: 'root' })
export class AuthService {

  readonly CONCILIATOR_TOKEN_KEY = 'authServiceToken';
  readonly CONCILIATOR_ROLES_KEY = 'authServiceRoles';
  readonly CONCILIATOR_USERNAME_KEY = 'authServiceName';
  readonly CONCILIATOR_USER_DATA_KEY = 'authServiceUserData';
  readonly CONCILIATOR_NAME = 'authServiceConciliatorName';
  readonly CONCILIATOR_ID = 'authServiceConciliatorID';

  private _authService: AuthServiceModel;
  private authService$: BehaviorSubject<AuthServiceModel>;

  private _userData: LoginModel;
  private userData$: BehaviorSubject<LoginModel>;

  private loggedIn = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private tokenService: TokenService
  ) {
    this._authService = new AuthServiceModel();
    this.authService$ = new BehaviorSubject(this._authService);

    this._userData = new LoginModel();
    this.userData$ = new BehaviorSubject(this._userData);
  }

  public getStorageData(): AuthServiceModel {
    const data = new AuthServiceModel();
    const strUser = sessionStorage.getItem(this.CONCILIATOR_USERNAME_KEY);
    const strToken = sessionStorage.getItem(this.CONCILIATOR_TOKEN_KEY);

    data.nome = strUser;
    data.sessionToken = strToken;

    try {
      if (data != null && data.sessionToken.trim().length > 0 && data.nome.trim().length > 0) {
        return data;
      }
    } catch {
      return null;
    }
  }

  public savedUserName(): string {
    return sessionStorage.getItem(this.CONCILIATOR_USERNAME_KEY);
  }

  public savedConciliatorName(): string {
    return sessionStorage.getItem(this.CONCILIATOR_NAME);
  }

  public savedConciliatorId(): number {
    return Number(sessionStorage.getItem(this.CONCILIATOR_ID));
  }

  public getUserRoles(): AdminRolesEnum[] {
    const storageItem = sessionStorage.getItem(this.CONCILIATOR_ROLES_KEY);
    if (storageItem) {
      return JSON.parse(storageItem) as AdminRolesEnum[];
    }
  }

  public isUserInRoles(roleList: AdminRolesEnum[]): boolean {
    return this.getUserRoles().some(userRole => roleList.includes(userRole));
  }

  public isThereUserRoles(): boolean {
    return this.getUserRoles().length > 0;
  }

  public getUserName() {
    if (!this._authService.nome) {
      this._authService.nome = this.savedUserName();
    }
    return this._authService.nome;
  }

  public getConciliatorName() {
    if (!this._authService.conciliatorName) {
      this._authService.conciliatorName = this.savedConciliatorName();
    }
    return this._authService.conciliatorName;
  }

  public getConciliatorId() {
    if (!this._authService.conciliatorId) {
      this._authService.conciliatorId = this.savedConciliatorId();
    }
    return this._authService.conciliatorId;
  }

  public setAuthService(authService: AuthServiceModel): boolean {
    if (authService.fgBloqueado === 'N') {
      this._authService = authService;

      const decodedToken = this.tokenService.getDecodedAccessToken(authService.sessionToken);

      if (!decodedToken) {
        return false;
      }

      this.setSessionInformation(
        { name: authService.nome, sessionToken: authService.sessionToken, roles: authService.roles,
                    conciliator: authService.conciliatorName, conciliatorId: authService.conciliatorId }, decodedToken);

      return true;
    }
    return false;
  }

  public getUserData(): TokenInfoModel {
    const sessionItem = sessionStorage.getItem(this.CONCILIATOR_USER_DATA_KEY);

    if (!sessionItem) {
      return;
    }

    return JSON.parse(sessionItem) as TokenInfoModel;
  }

  public setSessionInformation(sessionInfo: SessionInformation, tokenInfo: any) {
    sessionStorage.removeItem(this.CONCILIATOR_TOKEN_KEY);
    sessionStorage.removeItem(this.CONCILIATOR_USERNAME_KEY);
    sessionStorage.removeItem(this.CONCILIATOR_ROLES_KEY);
    sessionStorage.removeItem(this.CONCILIATOR_USER_DATA_KEY);
    sessionStorage.removeItem(this.CONCILIATOR_NAME);
    sessionStorage.removeItem(this.CONCILIATOR_ID);

    sessionStorage.setItem(this.CONCILIATOR_TOKEN_KEY, sessionInfo.sessionToken);
    sessionStorage.setItem(this.CONCILIATOR_USERNAME_KEY, sessionInfo.name);
    sessionStorage.setItem(this.CONCILIATOR_ROLES_KEY, JSON.stringify(sessionInfo.roles));
    sessionStorage.setItem(this.CONCILIATOR_USER_DATA_KEY, JSON.stringify(tokenInfo));
    sessionStorage.setItem(this.CONCILIATOR_NAME, sessionInfo.conciliator);
    sessionStorage.setItem(this.CONCILIATOR_ID, sessionInfo.conciliatorId.toString());
  }

  private clear() {
    sessionStorage.clear();

    this.loggedIn = false;
  }

  public logout() {
    this.clear();
    this.router.navigate(['/login']);
  }

  expiredToken() {
    this.logout();
  }

  isLoggedIn(): boolean {
    if (this.loggedIn) {
      return true;
    } else {
      this._authService = this.getStorageData();
      if (this.checkUser(this._authService)) {
        this.loggedIn = true;
        return true;
      } else {
        this.clear();
        return false;
      }
    }
  }

  private checkUser(authService: AuthServiceModel) {
    return authService && authService.sessionToken != null;
  }

  loginSuccess(loginData: AuthServiceModel) {
    if (this.checkUser(loginData)) {
      this.setAuthService(loginData);
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

}


export class SessionInformation {
  name: string;
  sessionToken: string;
  roles: string[];
  conciliator: string;
  conciliatorId: number;
}

export class TokenInfoModel {
  hasAccessAllServiceContracts: true;
  sub: string;
  aud: string;
  roles: string[];
  name: string;
  iss: string;
  channelCode?: string;
  channelType?: string;
  subChannelCode?: string;
  serviceContracts: number[];
  userType: UserTypeEnum;
  exp: number;
  iat: number;
}
