import { Pipe, PipeTransform } from '@angular/core';
const vanillaMasker = require('vanilla-masker');

@Pipe({
    name: 'cpfCnpj',
})
export class CpfCnpjPipe implements PipeTransform {
    transform(value: any) {
        const cpfPattern = '999.999.999-99';
        const cnpjPattern = '99.999.999/9999-99';

        if (!value) {
            return '';
        }

        if (value.length > 11) {
            return vanillaMasker.toPattern(value, cnpjPattern);
          }

        return vanillaMasker.toPattern(value, cpfPattern);
    }
}


