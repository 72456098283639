import { Component, OnDestroy, Input, ElementRef, Optional, Self, OnInit, Renderer2, Output, EventEmitter, HostBinding } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormBuilder, NgControl, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import {tap, takeWhile} from 'rxjs/internal/operators';
import {takeUntil} from 'rxjs/internal/operators';
import {FocusMonitor} from '@angular/cdk/a11y';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { SafeHtml } from '@angular/platform-browser';
import { UpperCasePipe, LowerCasePipe } from '@angular/common';

@Component({
  selector: 'fd-input',
  templateUrl: 'fd-input.component.html',
  styleUrls: ['fd-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: FdInputComponent}]
})

export class FdInputComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  static nextId = 0;
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  alive = true;

  @Input()
  field: FdInputConfig;

  @Output()
  input = new EventEmitter<string>();

  @Input()
  parentForm: FormGroup;

  @Output()
  blur = new EventEmitter<string>();

  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    return this.hasError;
  }

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'fd-input';
  describedBy = '';
  onChange = (_: any) => {};
  onTouched = () => {};

  get relatedFormControl(): AbstractControl{
    return this.parentForm.get(this.field.controlName);
  }

  get hasError(){
    return this.relatedFormControl && this.relatedFormControl.errors != null;
  }

  get errorMessages(){
    return Object.keys(this.field.messages)
                    .filter(val => this.relatedFormControl.errors[val])
                    .map(key => this.field.messages[key]);
  }


  get shouldLabelFloat() { return this.focused }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  constructor(
    formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private upperCasePipe: UpperCasePipe,
    private lowerCasePipe: LowerCasePipe,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    super();

    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  unmask(control: AbstractControl, maskCharsReplace: RegExp) : string {
    let unmaskedValue = '';
    if(!control) {
      return;
    }
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
          }
      }))
      .subscribe();

    return unmaskedValue;
  }

  ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  transformInput(control: AbstractControl, transformType: TransformType){
    let transformPipe: UpperCasePipe | LowerCasePipe;
    if(transformType === TransformType.UPPERCASE){
      transformPipe = this.upperCasePipe;
    }
    else if(transformType === TransformType.LOWERCASE){
      transformPipe = this.lowerCasePipe;
    }
    else{
      return;
    }

    if(!control) {
      return;
    }
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            control.setValue(transformPipe.transform(control.value), { emitEvent: false, emitModelToViewChange: false });
          }
      }))
      .subscribe();
  }

  ngOnInit(){
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    if(this.field && this.field.mask){
      this.unmask(this.relatedFormControl, this.field.maskCharsReplace);
    }

    if(this.field && this.field.transform){
      this.transformInput(this.relatedFormControl, this.field.transform);
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get required(): boolean {
    return this.relatedFormControl &&
           this.relatedFormControl.validator &&
           this.relatedFormControl.validator(this.relatedFormControl) != null &&
           this.relatedFormControl.validator(this.relatedFormControl).hasOwnProperty(this.REQUIRED_VALIDATOR_KEY);
  }

  get hasIcon(): boolean {
    return this.field && !!this.field.iconHTML;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  handleBlur(event: Event): void{
    if(this.blur){
      const inputValue = (event.target as HTMLInputElement).value;
      this.blur.emit(inputValue.replace(/\D+/g, ''));
    }
    event.stopPropagation();
  }

  handleChange(event: Event): void{
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }

}


export type Mask = (RegExp | string)[];

export enum InputType {
  DATE = 'date',
  EMAIL = 'email',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  RANGE = 'range',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week'
}

export enum TransformType {
  UPPERCASE = 'UPPERCASE',
  LOWERCASE = 'LOWERCASE'
}

export interface FdInputConfig {
  controlName: string;
  label: string;
  hint?: string;
  iconHTML?: SafeHtml;
  maxLength?: number;
  disabled?: boolean;
  transform?: TransformType;
  type?: InputType;
  mask?: Mask | ((value: string) => Mask);
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
}