import { environment } from 'src/environments/environment';
import { Utils } from '../../shared/utils/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(http) {
        this.http = http;
    }
    getAllUsers(filter, pageable) {
        const httpParams = Utils.mountHttpParams(filter, pageable);
        return this.http.get(`${environment.apiUrls.user}`, { params: httpParams });
    }
    profiles() {
        return this.http.get(`${environment.apiUrls.user}/profiles`);
    }
    userByDocument(document) {
        return this.http.get(`${environment.apiUrls.user}/${document}`);
    }
    setUserStatus(document, data) {
        return this.http.post(`${environment.apiUrls.user}/${document}/status`, data);
    }
    createUser(data) {
        return this.http.post(`${environment.apiUrls.user}`, data);
    }
    updateUser(data) {
        return this.http.post(`${environment.apiUrls.user}/edit`, data);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
