<div class="fd-accreditation-login" [fxFlex.md]="50" [fxFlex.lg]="75">
  <div class="fd-accreditation-login__title">
    <h4>ACESSO - CONCILIADORAS</h4>
    <img class="fd-accreditation-login__fiserv-logo" src="../../assets/img/fiserv-logo.png">
  </div>
  <div class="font-version">
    Versão: {{versiopPackage}}
  </div>
  <form [formGroup]="formGroup" (keyup.enter)="login()">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.user"></fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.password"></fd-input>
        <a class="right-text" (click)="goToPasswordRecovery()">Esqueci minha senha</a>
      </div>
    </div>
  </form>
  <div>
    <fd-button fdFatButton mat-flat-button [disabled]="!loginActive" [content]="'Entrar'" (trigger)="login()">
    </fd-button>
  </div>
</div>
