export interface MessageModel {
    title: string;
    description: string;
}

export class Messages implements MessageModel {

  static readonly GENERAL_ERROR = new Messages('Atenção', 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde');

  static readonly SESSION_TOKEN_EXPIRED = new Messages('Sessão expirada', 'Realize login novamente');

    static readonly DATA_REQUIRED_SEARCH_ERROR = new Messages('Não foi possivel realizar a busca',
      'Verifique o preenchimento dos filtros.');

    static readonly EDIT_SUCCESS = new Messages('Atenção', 'Alteração realizada com sucesso');
    static readonly SAVE_SUCCESS = new Messages('Atenção', 'Inserção realizada com sucesso');
    static readonly SEND_PROPOSAL_SUCCESS = new Messages('Sucesso!', 'Proposta Enviada com Sucesso!');

    static readonly EDIT_SAVE_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar a alteração');

    static readonly SEARCH_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar a requisição');

    static readonly EXPORT_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar exportação do arquivo');

    static readonly INVALID_FILE_TYPE_CSV = new Messages('Atenção', 'Tipo de arquivo inválido, o arquivo deverá ser do tipo .CSV');

    static readonly SERVICE_CONTRACT_LOAD_ERROR = new Messages('Atenção', 'Erro ao carregar service contracts. Tente novamente mais tarde');
    static readonly SERVICE_CONTRACT_NOT_FOUND = new Messages('Atenção', 'Esta instituição não possui service contracts.');

    static readonly LOGIN_ERROR = new Messages('Atenção', 'Não foi possível efetuar login. Tente novamente mais tarde');
    static readonly ACCESS_PERMISSION_DENIED = new Messages('Atenção', 'Você não possui acesso para visualizar este recurso');

    static readonly REQUIRED_FIELDS_DOCUMENT_INSTITUTION = new Messages('Campos obrigatórios',
      'Preencha os campos de adquirência e CNPJ/CPF para prosseguir ');

    static readonly NOT_FOUND_DOCUMENT_BY_INSTITUTION = new Messages('Atenção',
    'Documento não encontrado na instituição selecionada');

    static readonly ACCEPT_TERMS = new Messages('Atenção!', 'Aceite os termos para continuar!');
    static readonly CONFIRM_DISABLE_ACTION = new Messages('Atenção', 'Deseja realmente desabilitar o usuário?');
    static readonly CONFIRM_ENABLE_ACTION = new Messages('Atenção', 'Deseja habilitar o usuário?');

    static readonly TOKEN_SUCCESSFULLY_RESENT = new Messages('Atenção', 'Token reenviado com sucesso');
    static readonly TOKEN_RESEND_ERROR = new Messages('Atenção', 'Erro ao reenviar o token. Tente novamente mais tarde');
    static readonly DATA_REQUIRED_ERROR = new Messages('Não foi possivel salvar a alteração', 'Verifique o preenchimento dos campos.');

    static readonly CREATE_USER_LINK_SENT_TO_EMAIL = new Messages('Atenção',
      'Um link para finalizar o cadastro foi enviado para o seu e-mail');
    static readonly USER_ALREADY_EXISTS = new Messages('Atenção',
      'Usuário já existente em outro sistema. Para acessar, utilize a senha já definida.');

  static readonly SENDING_RETRY = new Messages('Sucesso!',
    'A proposta está sendo reprocessada. Acompanhe a listagem para obter o status de reprocessamento! Caso o erro persista, entre em contato com o atendimento.');
  static readonly FAILED_RETRY = new Messages('Sucesso!', 'Falha ao reprocessar proposta. Entre em contato com o atendiemento!');

  static readonly ERROR_VALID_DOCUMENT = new Messages('Atenção',
    'Ocorreu um erro ao validar o documento. Tente novamente ou entre em contato com o atendimento!');

  static readonly ERROR_PERSIST_PROPOSAL = new Messages('Atenção',
    'Ocorreu um erro ao persistir a proposta. Tente novamente ou entre em contato com o atendimento!');
  title: string;
    description: string;

    private constructor(private titleParam: string, private descriptionParam: string) {
        this.title = titleParam;
        this.description = descriptionParam;
    }

    toString() {
        return this.title;
    }
}
