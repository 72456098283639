import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { InputType } from '../shared/fd-form-components/fd-input/fd-input.component';
import { cpfMask } from '../shared/masks/document-masks';
import { Messages } from '../shared/messages/messages';
import { LoginService } from './services/login.service';
import { LoginModel } from '../shared/models/login.model';
import { Router } from '@angular/router';
import { AuthService } from '../shared/service/auth.service';
import { LoadingService } from '../shared/service/loading.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { Routes } from 'src/app/shared/routing/routes';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../environments/environment';
import { version } from 'package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  versiopPackage = version;
  loginActive = true;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private loginService: LoginService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private router: Router,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit() {
    this.loadingService.hide();
    this.startForms();
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.fields = {
      user: {
        label: 'CPF',
        disabled: false,
        controlName: 'user',
        mask: cpfMask,
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um usuário válido',
          invalid: 'Usuário inválido'
        }
      },
      password: {
        label: 'Senha',
        disabled: false,
        controlName: 'password',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe a senha',
          invalid: 'Senha inválida'
        }
      }
    };
  }

  goToPasswordRecovery() {
    this.document.location.href = environment.ADMIN_URL + 'password-recovery?type=4';
  }
  defineNextPage() {
    this.router.navigate([Routes.LIST_PROPOSALS]);
  }

  login() {

    this.loadingService.show();
    this.loginActive = false;

    const data = new LoginModel();
    data.cpfCnpj = this.formGroup.value.user;
    data.senha = this.formGroup.value.password;

    this.loginService.login(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        this.authService.loginSuccess(response.conteudo);
        this.defineNextPage();
        this.loginActive = true;
      }, (err: HttpErrorResponse) => {
        this.loginActive = true;
        this.errorService.handleXHRError(err, Messages.LOGIN_ERROR);
      });
  }
}
