import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoadingService {
    constructor() {
        this.loadingActive = false;
        this.loadingActive$ = new BehaviorSubject(this.loadingActive);
    }
    getLoadingStatusObservable() {
        return this.loadingActive$;
    }
    isLoadingActive() {
        return this.loadingActive;
    }
    hide() {
        this.loadingActive = false;
        this.loadingActive$.next(this.loadingActive);
    }
    show() {
        this.loadingActive = true;
        this.loadingActive$.next(this.loadingActive);
    }
}
LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
