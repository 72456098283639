import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserService} from '../../services/user.service';
import {finalize} from 'rxjs/operators';
import {LoadingService} from '../../../shared/service/loading.service';
import {UserModel} from '../../models/user.model';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  serviceContractList: string;
  userData: UserModel;
  constructor(private dialogRef: MatDialogRef<UserDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public document: string,
              private userService: UserService,
              private loadingService: LoadingService
            ) { }

  ngOnInit() {
    this.loadingService.show();
    this.userService.userByDocument(this.document)
      .pipe(finalize( () => this.loadingService.hide()))
      .subscribe(item => this.userData = item,
                 error => console.error(error));
  }

  close() {
    this.dialogRef.close();
  }

}
