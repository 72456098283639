import * as jwt_decode from 'jwt-decode';
import * as i0 from "@angular/core";
export class TokenService {
    constructor() { }
    getDecodedAccessToken(token) {
        try {
            return jwt_decode(token);
        }
        catch (Error) {
            console.log('[token.service] - Error when decoding token');
            return null;
        }
    }
}
TokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenService_Factory() { return new TokenService(); }, token: TokenService, providedIn: "root" });
