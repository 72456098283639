import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {ProfileModel} from '../models/profile.model';
import {UserListModel, UserModel} from '../models/user.model';
import {BaseResponseModel} from '../../shared/models/base-response.model';
import {Utils} from '../../shared/utils/utils';
import {UserFilterModel} from '../models/user-filter.model';
import {PageableModel} from '../../shared/models/pageable/pageable.model';
import {PageableResponseModel} from '../../shared/models/pageable/pageable-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAllUsers(filter: UserFilterModel, pageable: PageableModel): Observable<BaseResponseModel<PageableResponseModel<UserListModel[]>>> {
    const httpParams = Utils.mountHttpParams(filter, pageable);
    return this.http.get<BaseResponseModel<PageableResponseModel<UserListModel[]>>>(`${environment.apiUrls.user}`, {params: httpParams});
  }

  profiles(): Observable<BaseResponseModel<ProfileModel[]>> {
    return this.http.get<BaseResponseModel<ProfileModel[]>>(`${environment.apiUrls.user}/profiles`);
  }

  userByDocument(document: string): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.apiUrls.user}/${document}`);
  }

  setUserStatus(document: string, data: any) {
    return this.http.post<UserModel>(`${environment.apiUrls.user}/${document}/status`, data);
  }

  createUser(data: UserModel): Observable<BaseResponseModel<void>> {
    return this.http.post<BaseResponseModel<void>>(`${environment.apiUrls.user}`, data);
  }

  updateUser(data: UserModel): Observable<BaseResponseModel<void>> {
    return this.http.post<BaseResponseModel<void>>(`${environment.apiUrls.user}/edit`, data);
  }


}
