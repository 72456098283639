import { HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';

export class FdFieldBaseComponent implements ControlValueAccessor {

  @Input('errors')
  set errors(value: ValidationErrors) {
    this.errorIds = value && Object.keys(value) || [];
  }

  @HostBinding('class.fd-field--disabled') disabled = false;


  private _value: any;
  private _onChange: (anything) => void;
  private _onTouched: () => void;

  errorIds: string[] = [];

  set value(value: any) {
    this._value = value;
    this.onChange(value);
  }
  get value(): any {
    return this._value;
  }

  onTouched(): void {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  onChange(value: any): void {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: (anything) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
