import * as i0 from "@angular/core";
import * as i1 from "./dialog.service";
import * as i2 from "./file.service";
export class ErrorService {
    constructor(dialogService, fileService) {
        this.dialogService = dialogService;
        this.fileService = fileService;
    }
    handleXHRError(err, genericErrorMessage, callbackFn) {
        console.error(err);
        const serializedError = err.error ? err.error : null;
        if (serializedError && serializedError.mensagem) {
            if (callbackFn) {
                this.dialogService.openDialogWithMessage(serializedError.mensagem, () => callbackFn());
                return;
            }
            this.dialogService.openDialogWithMessage(serializedError.mensagem);
        }
        else {
            if (callbackFn) {
                this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
                return;
            }
            this.dialogService.openDialog(genericErrorMessage);
        }
    }
    handleXHRErrorDownload(err, genericErrorMessage, callbackFn) {
        const serializedError = err.error ? err.error : null;
        let fr = new FileReader();
        fr.readAsText(serializedError);
        fr.addEventListener('loadend', (e) => {
            const text = JSON.parse(fr.result.toString());
            if (text.codigoRetorno !== 0 && text.mensagem) {
                if (callbackFn) {
                    this.dialogService.openDialogWithMessage(text.mensagem, () => callbackFn());
                    return;
                }
                this.dialogService.openDialogWithMessage(text.mensagem);
                return;
            }
            else {
                if (callbackFn) {
                    this.dialogService.openDialog(genericErrorMessage, () => callbackFn());
                    return;
                }
                this.dialogService.openDialog(genericErrorMessage);
            }
            return;
        });
    }
}
ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.ɵɵinject(i1.DialogService), i0.ɵɵinject(i2.FileService)); }, token: ErrorService, providedIn: "root" });
