import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { sanitizeSearchFilter } from '../../utils/sanitize-search-filter';

@Component({
  selector: 'fd-select',
  templateUrl: './fd-select.component.html',
  styleUrls: ['./fd-select.component.scss']
})
export class FdSelectComponent extends FdFieldBaseComponent implements OnInit {

  private _items: Item[];
  private _filter: string;
  private _loadError: boolean;

  @Input()
  field: FdSelectConfig;

  @Input()
  parentForm: FormGroup;

  @Output()
  loadItemsFn = new EventEmitter<string>();

  @Output() selectionChange = new EventEmitter();

  @ViewChild('binding', {static: true}) binding: ElementRef;

  @Output()
  infiniteScroll = new EventEmitter<void>();

  limit = 10;
  offset = 0;
  controlName: string;
  context: any;
  label: string;
  accessibilityLabel?: string;
  placeholder?: string;
  items: Item[];
  hint?: string;
  messages?: { [key: string]: string };

  focusedItem?: Item;

  onChange = (_: any) => { };
  onTouched = () => { };

  constructor(private spinner: NgxSpinnerService) {
    super();
  }

  get searchable() {
    return this.field && this.field.searchable;
  }

  ngOnInit() {
    if(this.loadItemsFn){
      this.loadItemsFn.emit();
    }

    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }
  }

  getIfSelected() {
    if (this.field && this.field.items) {
      const selectedItems = this.field.items.filter(x => x.selected);

      if (selectedItems && selectedItems.length) {
        this.relatedFormControl.setValue(selectedItems[0].value);
      }
    }
    return null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get hasError() {
    return this.relatedFormControl.errors != null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter(val => this.relatedFormControl.errors[val])
      .map(key => this.field.messages[key]);
  }

  onSelectionChange() {
    this.selectionChange.emit();
  }

  handleSpace(event: KeyboardEvent) {
    if (event.keyCode === 32 || event.keyCode === 35 || event.keyCode === 36) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  get selectedItem() {
    return (this.relatedFormControl && this.relatedFormControl.value) || null;
  }

  filter(event: KeyboardEvent) {

    this.handleSpace(event);

    const target = event.target as HTMLInputElement;

    const searchValue = sanitizeSearchFilter(target.value);

    this.loadItemsFn.emit(searchValue);
  }
}

export interface Item {
  label: string;
  value: any;
  selected?: boolean;
  additionalProperties?: any;
  markAll?: boolean;
}

export interface FdSelectConfig {
  controlName: string;
  disabled?: boolean;
  required?: boolean;
  label: string;
  searchPlaceholder?: string;
  context?: any;
  messages?: { [key: string]: string };
  searchable?: boolean;
  items: Item[];
}

