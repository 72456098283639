import * as FileSaver from 'file-saver';
import * as i0 from "@angular/core";
export class FileService {
    constructor() { }
    saveFile(blobBuffer, type, fileName) {
        const fileStream = new Blob([blobBuffer], { type });
        FileSaver.saveAs(fileStream, fileName);
    }
}
FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(); }, token: FileService, providedIn: "root" });
