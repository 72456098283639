<nav mat-tab-nav-bar class="fd-accreditation-side-nav">
  <ul>
    <li *ngFor="let item of navItems" class="fd-accreditation-side-nav__nav-li">
      <ng-container *ngIf="isUserInRoles(item.allowedRoles)">
        <a mat-tab-link class="fd-accreditation-side-nav__nav-item" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" [routerLink]="item.link">
          <fa-icon icon="{{item.icon}}"></fa-icon>
          {{item.text}}
        </a>
        <div class="sub-list">
          <ul *ngIf="item.hasOptions">
            <li *ngFor="let option of item.options">
              <ng-container *ngIf="isUserInRoles(option.allowedRoles)">
                <a mat-tab-link routerLinkActive="activeSubItens" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="option.link">
                <fa-icon icon="{{option.icon}}"></fa-icon>
                {{option.text}}
              </a>
              </ng-container>
            </li>
          </ul>
        </div>
      </ng-container>
    </li>
  </ul>
</nav>