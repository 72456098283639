/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fd-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./fd-button.component";
import * as i4 from "@angular/platform-browser";
var styles_FdButtonComponent = [i0.styles];
var RenderType_FdButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FdButtonComponent, data: {} });
export { RenderType_FdButtonComponent as RenderType_FdButtonComponent };
export function View_FdButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "fd-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleTrigger($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "fd-button--primary": 0, "fd-button--secondary": 1, "fd-button--tertiary": 2, "fd-button--smallButton": 3, "fd-button--fatButton": 4, "fd-button--primaryLarge": 5, "fd-button--primaryMedium": 6, "fd-button--secondaryLarge": 7, "fd-button--disabled": 8, "fd-button--export": 9 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "d-flex  justify-content-center"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fd-button"; var currVal_2 = _ck(_v, 3, 0, _co.primary, _co.secondary, _co.tertiary, _co.smallButton, _co.fatButton, _co.primaryLarge, _co.primaryMedium, _co.secondaryLarge, _co.disabled, _co.export); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled || null); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.safeContent; _ck(_v, 4, 0, currVal_3); }); }
export function View_FdButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fd-button", [], null, null, null, View_FdButtonComponent_0, RenderType_FdButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.FdButtonComponent, [i4.DomSanitizer, [8, null], [8, null], [8, null], [8, null], [8, null], [8, null], [8, null], [8, null]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FdButtonComponentNgFactory = i1.ɵccf("fd-button", i3.FdButtonComponent, View_FdButtonComponent_Host_0, { content: "content", disabled: "disabled" }, { trigger: "trigger" }, []);
export { FdButtonComponentNgFactory as FdButtonComponentNgFactory };
