import {NgModule, LOCALE_ID} from '@angular/core';
import {ProposalComponent} from './proposal.component';
import {HttpClientModule} from '@angular/common/http';
import {ProposalService} from './services/proposal.service';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule, DatePipe} from '@angular/common';
import {FdFormComponentsModule} from '../shared/fd-form-components/fd-form-components.module';
import {MatDatepickerModule, MatSortModule, MatTabsModule, MatCheckboxModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {ListProposalComponent} from './components/list-proposal/list-proposal.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HierarchyService} from '../shared/service/hierarchy.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NewProposalsComponent} from './components/new-proposal/new-proposals.component';
import {PipesModule} from '../shared/pipes/pipes.module';

@NgModule({
  declarations: [ProposalComponent, ListProposalComponent, NewProposalsComponent],
    imports: [
        CommonModule,
        MatCheckboxModule,
        HttpClientModule,
        MatTableModule,
        MatInputModule,
        MatPaginatorModule,
        MatButtonModule,
        FdFormComponentsModule,
        MatDatepickerModule,
        MatTabsModule,
        ReactiveFormsModule,
        MatSortModule,
        FlexLayoutModule,
        FontAwesomeModule,
        PipesModule
    ],
  providers: [ProposalService, HierarchyService, DatePipe],
  entryComponents: [NewProposalsComponent],
})
export class ProposalModule {
}

