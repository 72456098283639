<div class="fd-modal__container">
        <fa-icon icon="exclamation-triangle" class="fd-modal__warning-icon" size="3x"></fa-icon>
        <div mat-dialog-content class="fd-modal__title">
          <p>{{data.title}}</p>
        </div>
        <div mat-dialog-content>
          <p>{{data.description}}</p>
        </div>
        <div mat-dialog-actions>
          <fd-button (keyup.enter)="handleClick()" class="fd-modal__close-button" fdSecondary [content]="'Fechar'" (trigger)="handleClick()">
          </fd-button>
        </div>
      </div>