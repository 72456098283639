import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CpfCnpjValidator} from '../../../shared/validators/cpf-cnpj-validator';
import {FdFieldConfigs} from '../../../shared/fd-form-components/fd-form-components.module';
import {cpfCnpjMask} from '../../../shared/masks/document-masks';
import {MatDialogRef} from '@angular/material/dialog';
import {ProposalModel} from '../../models/proposal.model';
import {ProposalService} from '../../services/proposal.service';
import {finalize} from 'rxjs/operators';
import {LoadingService} from '../../../shared/service/loading.service';
import {DialogService} from '../../../shared/service/dialog.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Messages} from '../../../shared/messages/messages';
import {ErrorService} from '../../../shared/service/error.service';
import {FdSelectConfig, Item} from '../../../shared/fd-form-components/fd-select/fd-select.component';
import {HierarchyService} from '../../../shared/service/hierarchy.service';
import {AuthService} from '../../../shared/service/auth.service';

@Component({
  selector: 'app-new-proposal',
  templateUrl: './new-proposals.component.html',
  styleUrls: ['./new-proposals.component.scss']
})
export class NewProposalsComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  acquisitionList: Item[];
  proposal: ProposalModel = new ProposalModel();
  validDocument = false;
  acceptedOptIn: boolean;

  booleanOptions: Item[] = [
    {
      label: 'Sim',
      value: true
    },
    {
      label: 'Não',
      value: false
    }
  ];

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<NewProposalsComponent>,
              private dialogService: DialogService,
              private proposalService: ProposalService,
              private hierarchyService: HierarchyService,
              private loadingService: LoadingService,
              private authService: AuthService,
              private errorService: ErrorService) {}

  ngOnInit(): void {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      cnpj: ['', [Validators.required, CpfCnpjValidator]],
      fantasyName: ['', Validators.required],
      socialReason: ['', Validators.required],
      comments: [''],
      acquisition: ['', Validators.required],
      optin: ['', Validators.required],
    });
  }

  documentChange(field: string) {
    const document =  this.formGroup.value.cnpj || null;
    const institution =  this.formGroup.value.acquisition.idInstitution || null;
    if (!document || !institution) {
      if (field !== 'institution') {
        this.dialogService.openDialog(Messages.REQUIRED_FIELDS_DOCUMENT_INSTITUTION);
      }
      return;
    }

    this.loadingService.show();
    this.proposalService.existsDocumentByInstitution(institution, document)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        this.validDocument = true;
        this.enabledDisabledFields(true);
      }, (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.validDocument = false;
          this.enabledDisabledFields(false);
          this.errorService.handleXHRError(err, Messages.NOT_FOUND_DOCUMENT_BY_INSTITUTION);
        } else {
          this.dialogService.openDialog(Messages.ERROR_VALID_DOCUMENT);
        }
      });
  }

  enabledDisabledFields(enabled: boolean) {
    if (enabled) {
      this.formGroup.controls.comments.enable();
      this.formGroup.controls.fantasyName.enable();
      this.formGroup.controls.socialReason.enable();
      this.formGroup.controls.optin.enable();
    } else {
      this.formGroup.controls.comments.disable();
      this.formGroup.controls.fantasyName.disable();
      this.formGroup.controls.socialReason.disable();
      this.formGroup.controls.optin.disable();
    }
  }

  close() {
    this.dialogRef.close();
  }

  loadAcquisitions(): void {
    this.loadingService.show();
    this.hierarchyService.acquisition()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.acquisitionList = list;
          (this.fields.acquisition as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  save() {
    if (!this.validDocument) {
      this.dialogService.openDialog(Messages.NOT_FOUND_DOCUMENT_BY_INSTITUTION);
      return;
    }

    if (!this.formGroup.controls.optin.value) {
      this.dialogService.openDialog(Messages.ACCEPT_TERMS);
      return;
    }

    if (!this.formGroup.valid) {
      return;
    }

    this.proposal.document =  this.formGroup.value.cnpj || null;
    this.proposal.comments =  this.formGroup.value.comments || null;
    this.proposal.fantasyName =  this.formGroup.value.fantasyName || null;
    this.proposal.socialReason =  this.formGroup.value.socialReason || null;
    this.proposal.institutionId =  this.formGroup.value.acquisition.idInstitution || null;
    this.proposal.idConciliator =  this.authService.getConciliatorId();
    this.proposal.serviceContract = this.formGroup.value.acquisition.serviceContract || null;

    this.loadingService.show();
    this.proposalService.save(this.proposal)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.SEND_PROPOSAL_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.validDocument = false;
          this.enabledDisabledFields(false);
          this.errorService.handleXHRError(err, Messages.NOT_FOUND_DOCUMENT_BY_INSTITUTION);
        } else {
          this.dialogService.openDialog(Messages.ERROR_VALID_DOCUMENT);
        }
      });
  }

  changeOption(event) {
    this.acceptedOptIn = event;
  }

  createFields(): FdFieldConfigs {
    return {
      cnpj: {
        label: 'CPF/CNPJ',
        maskCharsReplace: /[.\/ -]/g,
        mask: cpfCnpjMask,
        controlName: 'cnpj',
        messages: {
          required: 'Informe um CPF/CNPJ',
          invalidCnpj: 'CNPJ inválido',
        }
      },
      fantasyName: {
        label: 'Nome Fantasia',
        controlName: 'fantasyName',
        maxLength: 100,
        disabled: true,
        messages: {
          required: 'Informe um nome fantasia'
        }
      },
      socialReason: {
        label: 'Razão Social',
        controlName: 'socialReason',
        maxLength: 100,
        disabled: true,
        messages: {
          required: 'Informe uma razão social'
        }
      },
      comments: {
        label: 'Observação',
        disabled: true,
        maxLength: 150,
        controlName: 'comments',
        messages: {}
      },
      optin: {
        label: 'O estabelecimento autoriza a Fiserv BIN a enviar o Extrato EDI a conciliadora',
        items: this.booleanOptions,
        disabled: true,
        controlName: 'optin',
        messages: {
          required: 'Aceite os termos para continuar',
        }
      },
      acquisition: {
        label: 'Adquirência',
        items: [],
        searchPlaceholder: 'Digite algo',
        controlName: 'acquisition',
        messages: {
          required: 'Informe uma Adquirência',
        }
      }
    };
  }

}



