import { environment } from 'src/environments/environment';
import { Utils } from '../../shared/utils/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/service/auth.service";
export class ProposalService {
    constructor(httpClient, authService) {
        this.httpClient = httpClient;
        this.authService = authService;
    }
    save(proposal) {
        return this.httpClient.post(`${environment.apiUrls.proposal}`, proposal);
    }
    searchProposals(filter, pageable) {
        const httpParams = Utils.mountHttpParams(filter, pageable);
        return this.httpClient.get(`${environment.apiUrls.proposal}`, { params: httpParams });
    }
    excelReport(filter, pageable) {
        const httpParams = Utils.mountHttpParams(filter, pageable);
        return this.httpClient.get(`${environment.apiUrls.proposal}/excel-report`, { params: httpParams });
    }
    existsDocumentByInstitution(institutionId, document) {
        return this.httpClient.get(`${environment.apiUrls.merchants}/${institutionId}/${document}/exists`);
    }
    retryProposal(idProposal) {
        return this.httpClient.post(`${environment.apiUrls.proposal}/${idProposal}/retry`, {});
    }
}
ProposalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProposalService_Factory() { return new ProposalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: ProposalService, providedIn: "root" });
