import {HttpParams} from '@angular/common/http';

export class Utils {

  static mountHttpParams(...objects: any[]): HttpParams {
    let httpParams = new HttpParams();
    objects.forEach(obj => {
      Object.keys(obj).forEach((key) => {
        if (obj[key]) {
          httpParams = httpParams.append(key, obj[key]);
        }
      });
    });
    return httpParams;
  }
}
