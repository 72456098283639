<h3 fxLayoutAlign="center" *ngIf="update">Editar usuário</h3>
<h3 fxLayoutAlign="center" *ngIf="!update">Novo usuário</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input (blur)="cpfCnpjChange($event)" [parentForm]="formGroup" [field]="fields.cpfCnpj"></fd-input>
        </div>
        <div fxFlex="50">
          <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
        </div>

      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input [parentForm]="formGroup" [field]="fields.mobileNumber"></fd-input>
        </div>
        <div fxFlex="50">
          <fd-input [parentForm]="formGroup" [field]="fields.email"></fd-input>
        </div>
      </div>
      <ng-container>
        <div>
          <fd-select (loadItemsFn)="loadProfiles()" [parentForm]="formGroup" [field]="fields.userProfile">
          </fd-select>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
<div mat-dialog-actions>
  <div>
    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="saveOrUpdate()"> </fd-button>
  </div>
</div>
