<div *ngIf="hasValue">
  <table mat-table matSort class="table" (matSortChange)="changeSort($event)" [dataSource]="dataSource.data">

    <ng-container matColumnDef="cpfCnpj" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF/CNPJ</th>
      <td mat-cell *matCellDef="let element"> {{element.cpfCnpj | cpfCnpj}} </td>
    </ng-container>

    <ng-container matColumnDef="name" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="profile.description" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Perfil</th>
      <td mat-cell *matCellDef="let element"> {{element.profileName}} </td>
    </ng-container>

    <ng-container matColumnDef="mobileNumber" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefone</th>
      <td mat-cell *matCellDef="let element"> {{element.mobileNumber | phone}} </td>
    </ng-container>

    <ng-container matColumnDef="email" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="options_edit" class="table_column">
      <th mat-header-cell *matHeaderCellDef>Detalhes</th>
      <td mat-cell *matCellDef="let element" class="options">
        <ng-container>
          <fa-icon class="edit-link" matTooltip="Detalhes da proposta" (click)="detail(element)" icon="file"></fa-icon>
        </ng-container>
        <ng-container>
          <fa-icon class="edit-link" matTooltip="Editar Conciliadora" icon="edit" (click)="edit(element)"></fa-icon>
        </ng-container>
        <ng-container *ngIf="isEnabled(element)">
          <fa-icon class="edit-link" matTooltip="Desabilitar usuário" icon="toggle-on" (click)="disable(element)"></fa-icon>
        </ng-container>
        <ng-container *ngIf="!isEnabled(element)">
          <fa-icon class="edit-link" matTooltip="Habilitar usuário" icon="toggle-off" (click)="enable(element)"></fa-icon>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator *ngIf="hasValue" #paginator
                 [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
                 (page)="change($event)" [length]="totalPages"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
