export enum ProposalStatusEnum {
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  PROCESSED = 'PROCESSED',
  PENDING_BANK_WORKS = 'PENDING_BANK_WORKS',
  PENDING_BASE_PRODUCTS = 'PENDING_BASE_PRODUCTS',
  ERROR_BANK_WORKS = 'ERROR_BANK_WORKS',
  ERROR_BASE_PRODUCTS = 'ERROR_BASE_PRODUCTS',
  PROCESSING = 'PROCESSING',
  PARTIALLY_PROCESSED_BANK_WORKS = 'PARTIALLY_PROCESSED_BANK_WORKS',
  PARTIALLY_PROCESSED_BASE_PRODUCTS = 'PARTIALLY_PROCESSED_BASE_PRODUCTS',
  ERROR = 'ERROR',
}
