import { OnDestroy } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faBars, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import ptBr from '@angular/common/locales/pt';
import { getPortuguesePaginatorIntl } from '../../intl/pt-BR/paginator-intl';
registerLocaleData(ptBr);
const ɵ0 = getPortuguesePaginatorIntl();
export class AppModule {
    constructor(spinner, loadingService) {
        this.spinner = spinner;
        this.loadingService = loadingService;
        this.alive = true;
        this.loadingService.getLoadingStatusObservable().subscribe(isActive => {
            if (isActive) {
                this.spinner.show();
            }
            else {
                this.spinner.hide();
            }
        });
        library.add(faPlusCircle, faTimes, faBars, faBan);
    }
    ngOnDestroy() {
        this.alive = false;
    }
}
export { ɵ0 };
