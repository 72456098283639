<h3 fxLayoutAlign="center">Nova Proposta</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="33">
          <fd-select (loadItemsFn)="loadAcquisitions()" [parentForm]="formGroup"
                     [field]="fields.acquisition" (selectionChange)="documentChange('institution')">
          </fd-select>
        </div>
        <div fxFlex="33">
          <fd-input (blur)="documentChange('document')" [parentForm]="formGroup" [field]="fields.cnpj"></fd-input>
        </div>
      </div>
    </ng-container>
  </div>
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.fantasyName"></fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.socialReason"></fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.comments"></fd-input>
        </div>
      </div>
    </ng-container>
  </div>

  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-checkbox [parentForm]="formGroup" (change)="changeOption($event)"
                       [field]="fields.optin">
          </fd-checkbox>
        </div>
      </div>
    </ng-container>
  </div>


  <div mat-dialog-actions>
    <div class="value-edit-table__button-container">
      <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"></fd-button>
      <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
      </fd-button>
    </div>
  </div>
</form>



