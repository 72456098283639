/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fd-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../fd-button/fd-button.component.ngfactory";
import * as i7 from "../fd-button/fd-button.component";
import * as i8 from "./fd-alert.component";
var styles_FdAlertComponent = [i0.styles];
var RenderType_FdAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FdAlertComponent, data: {} });
export { RenderType_FdAlertComponent as RenderType_FdAlertComponent };
export function View_FdAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "fd-modal__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "fd-modal__warning-icon ng-fa-icon"], ["icon", "exclamation-triangle"], ["size", "3x"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "fd-modal__title mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i5.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "fd-button", [["class", "fd-modal__close-button"], ["fdSecondary", ""]], null, [[null, "keyup.enter"], [null, "trigger"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.enter" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("trigger" === en)) {
        var pd_1 = (_co.handleClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_FdButtonComponent_0, i6.RenderType_FdButtonComponent)), i1.ɵdid(14, 114688, null, 0, i7.FdButtonComponent, [i4.DomSanitizer, [8, null], [8, ""], [8, null], [8, null], [8, null], [8, null], [8, null], [8, null]], { content: [0, "content"] }, { trigger: "trigger" })], function (_ck, _v) { var currVal_1 = "exclamation-triangle"; var currVal_2 = "3x"; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_5 = "Fechar"; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.data.title; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.data.description; _ck(_v, 10, 0, currVal_4); }); }
export function View_FdAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fd-alert", [], null, null, null, View_FdAlertComponent_0, RenderType_FdAlertComponent)), i1.ɵdid(1, 114688, null, 0, i8.FdAlertComponent, [i5.MatDialogRef, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FdAlertComponentNgFactory = i1.ɵccf("app-fd-alert", i8.FdAlertComponent, View_FdAlertComponent_Host_0, {}, { buttonClicked: "buttonClicked" }, []);
export { FdAlertComponentNgFactory as FdAlertComponentNgFactory };
