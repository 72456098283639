const digitRegExp = /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑýÝ@.0-9!#$%&*+=/?\^_`{|}~'\-]/;

export function EmailMask(value) {
  const arr = value
    .split('')
    .map(function (char) {
      if (digitRegExp.test(char)) {
        return digitRegExp;
      }
    });

  return arr;
}
