import { ModalDefinitions, FdAlertComponent } from '../fd-form-components/fd-alert/fd-alert.component';
import { FdConfirmComponent } from '../fd-form-components/fd-confirm/fd-confirm.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./loading.service";
export class DialogService {
    constructor(dialog, loadingService) {
        this.dialog = dialog;
        this.loadingService = loadingService;
    }
    openConfirmDialog(message, confirmCallbackFn, cancelCallbackFn) {
        this.loadingService.hide();
        const dialogData = {
            description: message.description,
            title: message.title,
            confirmCallbackFn,
            cancelCallbackFn
        };
        const dialogRef = this.dialog.open(FdConfirmComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData
        });
        // Uncomment if any action is necessary after modal close.
        // dialogRef.afterClosed().subscribe(result => {
        //   console.log('The dialog was closed');
        // });
    }
    openDialogWithMessage(message, callbackFn) {
        this.loadingService.hide();
        const dialogData = {
            description: message,
            title: 'Atenção',
            callbackFn
        };
        if (callbackFn) {
            dialogData.callbackFn = callbackFn;
        }
        const dialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData
        });
        // Uncomment if any action is necessary after modal close.
        // dialogRef.afterClosed().subscribe(result => {
        //   console.log('The dialog was closed');
        // });
    }
    openDialog(message, callbackFn) {
        this.loadingService.hide();
        const dialogData = {
            description: message.description,
            title: message.title,
            callbackFn
        };
        if (callbackFn) {
            dialogData.callbackFn = callbackFn;
        }
        const dialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData
        });
        // Uncomment if any action is necessary after modal close.
        // dialogRef.afterClosed().subscribe(result => {
        //   console.log('The dialog was closed');
        // });
    }
    openDialogRunFunction(message, callbackFn) {
        this.loadingService.hide();
        const dialogData = {
            description: message.description,
            title: message.title,
            callbackFn
        };
        if (callbackFn) {
            dialogData.callbackFn = callbackFn;
        }
        const dialogRef = this.dialog.open(FdAlertComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
            callbackFn();
        });
    }
}
DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.LoadingService)); }, token: DialogService, providedIn: "root" });
