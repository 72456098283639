// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import 'zone.js/dist/zone';
import * as ApiBaseUat from './api-base/api-base-uat';
export const apiBase = `${ApiBaseUat.apiBase}/bwa`;
export const configApiBase = ApiBaseUat.configApiBase;
export const environment = {
  production: false,
  BASE_URL: `${apiBase}/backoffice`,
  apiKey: 'IeBgvvjzmqQU47qXRyKwYX0ohFucum0H',
  ACCREDITATION_URL: ApiBaseUat.accreditationUrl,
  ADMIN_URL:  `${ApiBaseUat.adminUrl}/`,
  apiUrls: {
    institutions: `${apiBase}/conciliator-api/institutions`,
    authService: `${apiBase}/conciliator-api/authentication/login`,
    proposal: `${apiBase}/conciliator-api/proposals`,
    searchProposalStatus: `${apiBase}/conciliator-api/proposal/status`,
    user: `${apiBase}/conciliator-api/users`,
    userEmail: `${apiBase}/conciliator-api/user-email`,
    merchants: `${apiBase}/conciliator-api/merchants`,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
