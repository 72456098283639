import { Component } from '@angular/core';
import { SideNavItems } from 'src/app/core/layout/components/side-nav/side-nav.items';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {

  navItems = SideNavItems;

  constructor(private authService: AuthService) { }

  isUserInRoles(roles: AdminRolesEnum[]){
    return this.authService.isUserInRoles(roles);
  }

}
