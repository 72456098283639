import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import { FileContentTypeEnum } from '../enums/file-content-type.enum';
import { FileExtensionEnum } from '../enums/file-extension.enum';
import { FileService } from './file.service';


const EXCEL_EXTENSION = '.xlsx';
const HEADERS = ['Id.', 'CPF/CNPJ', 'Nome Fantasia', 'Razão Social', 'Conciliadora', 'Data de Criação',
                'Status', 'CPF/CNPJ Vendedor', 'Comentários', 'Nome do vendedor'];

@Injectable()
export class ExportExcelService {

    constructor(private fileService: FileService) {
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = renameHeaders(XLSX.utils.json_to_sheet(json));

        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const fileName = excelFileName + FileExtensionEnum.EXCEL;

        this.fileService.saveFile(excelBuffer, FileContentTypeEnum.EXCEL, fileName);
    }
}

function renameHeaders(sheet: XLSX.WorkSheet) {
    var range = XLSX.utils.decode_range(sheet['!ref']);
    for(var i = range.s.c; i <= range.e.c; ++i) {
        var address = XLSX.utils.encode_col(i) + "1";
        if(!sheet[address]) continue;
        sheet[address].v = HEADERS[i];
    }

    return sheet;
}
