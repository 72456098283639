import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProposalComponent} from './proposal/proposal.component';
import {LoginComponent} from './login/login.component';
import {CoreComponent} from './core/core.component';
import {BackofficeGuard} from './shared/guards/backoffice.guard';
import {UserComponent} from './user/user.component';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    children: [
      { path: '', component: LoginComponent },
    ]
  },
  {
    path: '',
    component: CoreComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'proposal/list',
        component: ProposalComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'user/list',
        component: UserComponent,
        canActivate: [BackofficeGuard]
      }
    ]
  },
  { path: '**', redirectTo: '/login' },
  { path: '*', redirectTo: '/login' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
