import { Component, OnInit, Input, ElementRef, Optional, Self, EventEmitter, Output, HostBinding, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { AbstractControl, FormBuilder, NgControl, FormGroup } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { takeWhile, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { DateFormats } from '../../enums/date-formats.enum';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';


@Component({
  selector: 'fd-datepicker',
  templateUrl: './fd-datepicker.component.html',
  styleUrls: ['./fd-datepicker.component.scss']
})
export class FdDatepickerComponent extends FdFieldBaseComponent implements OnInit {

  dateMask = MaskedDate;

  static nextId = 0;
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  alive = true;

  @ViewChild('datepicker', { static: true }) datepickerRef;

  @Input()
  field: FdDatepickerConfig;

  @Output()
  input = new EventEmitter<string>();

  @Input()
  parentForm: FormGroup;

  @Output()
  blur = new EventEmitter<string>();

  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    return this.hasError;
  }

  minDate = new Date(1900, 0, 1);
  maxDate = new Date(2099, 11, 31);

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'fd-input';
  describedBy = '';
  onChange = (_: any) => {};
  onTouched = () => {};

  get relatedFormControl(): AbstractControl{
    return this.parentForm.get(this.field.controlName);
  }

  get hasError(){
    return this.relatedFormControl && this.relatedFormControl.errors != null;
  }

  get errorMessages(){
    return Object.keys(this.field.messages)
                    .filter(val => this.relatedFormControl.errors[val])
                    .map(key => this.field.messages[key]);
  }


  get shouldLabelFloat() { return this.focused }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  constructor(
    formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private focusMonitor: FocusMonitor,
    private dateAdapter: DateAdapter<Date>,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    super();

    this.dateAdapter.setLocale('pt-PT');   

    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  unmask(control: AbstractControl, maskCharsReplace: RegExp) : string {
    let unmaskedValue = '';
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value && typeof value === 'string') {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
          }
      }))
      .subscribe();

    return unmaskedValue;
  }

  ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  ngOnInit(){
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    if(this.field && this.field.mask){
      this.unmask(this.relatedFormControl, this.field.maskCharsReplace);
    }

    this.relatedFormControl.valueChanges
    .subscribe(value => {
      if(value){

        const format = this.field && this.field.valueFormat ? this.field.valueFormat : DateFormats.DAY_MONTH_YEAR_WITH_SLASH;
        
        this.relatedFormControl.setValue(this.datePipe.transform(value, format), { emitEvent: false, emitModelToViewChange: false });
      }
    });

  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get required(): boolean {
    return this.relatedFormControl &&
           this.relatedFormControl.validator &&
           this.relatedFormControl.validator(this.relatedFormControl) != null &&
           this.relatedFormControl.validator(this.relatedFormControl).hasOwnProperty(this.REQUIRED_VALIDATOR_KEY);
  }

  get hasIcon(): boolean {
    return this.field && !!this.field.iconHTML;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  handleBlur(event: Event): void{
    if(this.blur){
      const inputValue = (event.target as HTMLInputElement).value;
      this.blur.emit(inputValue.replace(/\D+/g, ''));
    }
    event.stopPropagation();
  }
  handleChange(event: Event): void{
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }

}


export type Mask = (RegExp | string)[];

export interface FdDatepickerConfig {
  controlName: string;
  label: string;
  hint?: string;
  valueFormat?: string | DateFormats;
  iconHTML?: SafeHtml;
  maxLength?: number;
  disabled?: boolean;
  mask?: Mask | ((value: string) => Mask);
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
}

export function MaskedDate(event: any) {
  var v = event.target.value;
  if (v.match(/^\d{2}$/) !== null) {
    event.target.value = v + '/';
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    event.target.value = v + '/';
}
}