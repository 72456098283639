import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { InstitutionModel } from 'src/app/shared/models/institution.model';
import { ServiceContractModel } from 'src/app/shared/models/service-contract.model';
import { sortBy } from 'sort-by-typescript';
import { InstitutionAndServiceContractResponseModel } from '../models/response/institution-and-service-contract-response.model';
import { InstitutionAndServiceContractModel } from '../models/institution-and-service-contract-model';
import { AcquisitionModel } from '../models/acquisition.model';


@Injectable()
export class HierarchyService {
  constructor(private http: HttpClient) { }

  institution(): Observable<Item[]> {
    return this.http.get<InstitutionModel[]>(`${environment.apiUrls.institutions}/getAllActive`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToInstitutionModels(x)));
  }

  acquisition(): Observable<Item[]> {
    return this.http.get<AcquisitionModel[]>(`${environment.apiUrls.institutions}/getAllAcquisition`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToAcquisitionModels(x)));
  }

  bankList(): Observable<Item[]> {
    return this.http.get<any[]>(`${environment.apiUrls.institutions}/bank`)
    .pipe(map(x => this.invalidResponse(x) ? null : this.mapToBankModel(x)));
  }

  serviceContractByInstitution(institutionId: string): Observable<Item[]> {
    return this.http.get<ServiceContractModel[]>(`${environment.apiUrls.institutions}/service-contract/${institutionId}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToServiceContractModels(x)));
  }

  mapToInstitutionAndServiceContractModels(response: InstitutionAndServiceContractResponseModel[]): InstitutionAndServiceContractModel[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      id: value.id,
      name: value.name,
      serviceContracts: value.serviceContracts.map(sc => ({
        value: sc.id,
        label: sc.name
      }))
    }));
  }

  private mapToServiceContractModels(response: ServiceContractModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id,
      label: `${value.id} - ${value.name}`
    }));
  }

  private mapToInstitutionModels(response: InstitutionModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.id,
      label: `${value.id} - ${value.name}`,
    }));
  }

  private mapToAcquisitionModels(response: AcquisitionModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
            .sort(sortBy('idInstitution'))
            .map(value =>({
              value: value,
              label: value.name,
            }));
  }


  mapToBankModel(response: any[]): Item[] {
    if (!response) {
      return [];
    }
    return response.sort(sortBy('id'))
    .map(value => ({
      value: value.bankNumber,
      label: `${value.bankNumber} - ${value.bankName}`
    }));
  }

  // VALID RESPONSE
  private invalidResponse(baseApiResponse: any[]): boolean {
    return !baseApiResponse;
  }
}
