import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FdFieldConfig, FdFieldConfigs} from '../shared/fd-form-components/fd-form-components.module';
import {FdSelectConfig, Item} from '../shared/fd-form-components/fd-select/fd-select.component';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTabChangeEvent,
  MatTabGroup,
  MatTableDataSource,
  PageEvent
} from '@angular/material';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ProposalService} from './services/proposal.service';
import {SearchProposalModel} from './models/search-proposal.model';
import {FdAlertComponent, ModalDefinitions} from '../shared/fd-form-components/fd-alert/fd-alert.component';
import {Messages} from '../shared/messages/messages';
import {finalize} from 'rxjs/operators';
import {HierarchyService} from '../shared/service/hierarchy.service';
import {ExportExcelService} from '../shared/service/export-excel.service';
import {LoadingService} from '../shared/service/loading.service';
import {DateFormats} from '../shared/enums/date-formats.enum';
import {cpfCnpjMask} from '../shared/masks/document-masks';
import {DialogService} from '../shared/service/dialog.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../shared/service/error.service';
import {AuthService} from '../shared/service/auth.service';
import {sanitizeSearchFilter} from '../shared/utils/sanitize-search-filter';
import {NewProposalsComponent} from './components/new-proposal/new-proposals.component';
import {ProposalListModel} from './models/proposal-list.model';
import {AdminRolesEnum} from '../shared/enums/admin-roles.enum';
import {ProposalStatusEnum} from '../shared/enums/proposal-status.enum';
import {PageableModel, SortTable} from '../shared/models/pageable/pageable.model';
import { ProposalReportModel } from './models/proposal-report.model';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class ProposalComponent implements OnInit {

  sizeItems = 50;
  totalPages = 0;
  pageNumber = 0;
  exportValid = false;
  institutionList: Item[];
  today = new Date();


  dataSource = new MatTableDataSource<ProposalListModel>();
  dataExport = new Array<ProposalReportModel>();

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  sortPageable: string = undefined;

  proposalStatus: Item[] = [
    {
      label: 'Processado',
      value: ProposalStatusEnum.PROCESSED
    },
    {
      label: 'Parcialmente Processado',
      value: ProposalStatusEnum.PARTIALLY_PROCESSED
    },
    {
      label: 'Em Processamento',
      value: ProposalStatusEnum.PROCESSING
    },
    {
      label: 'Erro',
      value: ProposalStatusEnum.ERROR
    }
  ];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('childTabs') childTabs: QueryList<MatTabGroup>;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: HierarchyService,
    private service: ProposalService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private errorService: ErrorService,
    private authService: AuthService,
    private proposalService: ProposalService,
    private exportExcelService: ExportExcelService,
    private dialogService: DialogService,
    private hierarchyService: HierarchyService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: any

  ) { }

  ngOnInit() {
    this.startForms();

    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
  }

  searchProposals() {
    this.dataSource = new MatTableDataSource<ProposalListModel>();
    this.pageNumber = 0;
    this.findProposals();
  }

  buildFilter() {
    const filter = new SearchProposalModel();
    filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'yyyy-MM-dd');
    filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'yyyy-MM-dd');
    filter.institutionId = this.formGroup.value.institutionNumber;
    filter.document = this.formGroup.value.cpfCnpj ? this.formGroup.value.cpfCnpj.match(/\d/g).join('') : undefined;
    filter.statusId = this.formGroup.value.proposalsState;
    return filter;
  }

  buildFPageable() {
    const pageable = new PageableModel(this.pageNumber, this.sizeItems);
    if (this.sortPageable) {
      pageable.sort = this.sortPageable;
    }
    return pageable;
  }

  generateXlsx(data: ProposalReportModel[]) {
    this.dataExport = data;
    this.exportExcelService.exportAsExcelFile(
      this.dataExport,
      'proposals-conciliator' + '_' +
      this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') + '_' +
      this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')
    );
  }

  findProposals(event?: SortTable): void {
    const filter = this.buildFilter();
    const pageable = new PageableModel(this.pageNumber, this.sizeItems);
    if (event) {
      pageable.setSort(event.direction, event.active);
    } else {
      pageable.setSort('desc', 'id');
    }

    if (!filter.startDate || !filter.endDate) {
      this.loadingService.hide();
      this.dialog.open(FdAlertComponent,
        {disableClose: true, width: ModalDefinitions.DEFAULT_MODAL_WIDTH, data: Messages.DATA_REQUIRED_SEARCH_ERROR});
      return;
    }

    this.loadingService.show();
    this.service.searchProposals(filter, pageable)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        const item = response.conteudo;
        this.exportValid = false;
        this.dataSource = new MatTableDataSource(item.content);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalPages = item.totalElements;
        this.pageNumber = item.number;
      }, () => {
        this.dialog.open(FdAlertComponent, {disableClose: true, width: ModalDefinitions.DEFAULT_MODAL_WIDTH, data: Messages.SEARCH_ERROR});
      });
  }

  exportGrid(): void {
    const filter = this.buildFilter();
    const pageable = this.buildFPageable();

    this.service.excelReport(filter, pageable)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(response => {
      const item = response.conteudo;
      this.generateXlsx(item);
    }, () => {
      this.dialog.open(FdAlertComponent, {disableClose: true, width: ModalDefinitions.DEFAULT_MODAL_WIDTH, data: Messages.SEARCH_ERROR});
    });
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposals();
  }

  changeSortPage(event: SortTable): void {
    this.findProposals(event);
  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    (field as FdSelectConfig).items = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 ||
      sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
    this.formGroup.controls.institutionNumber.setValue('');
    this.formGroup.controls.proposalsState.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');
  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
  }

  loadInstitutions(filterValue: string): void {

    if (filterValue) {
      this.filterDropdowns(filterValue, this.institutionList, this.fields.institutionNumber);
      return;
    }

    this.loadingService.show();
    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.institutionList = list;
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  // FORMS
  startForms(): void {

    this.formGroup = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      institutionNumber: [''],
      cpfCnpj: [''],
      proposalsState: [''],
    });

    this.fields = {
      startDate: {
        label: 'Data Inicial',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'startDate',
        messages: {
          invalid: 'Data inválida',
        }
      },
      endDate: {
        label: 'Data Final',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'endDate',
        messages: {
          invalid: 'Data inválida',
        }
      },
      institutionNumber: {
        label: 'Instituição',
        items: [],
        searchable: true,
        searchPlaceholder: 'Digite algo',
        controlName: 'institutionNumber'
      },
      cpfCnpj: {
        label: 'CPF/CNPJ',
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj'
      },
      proposalsState: {
        label: 'Status da Proposta',
        items: this.proposalStatus,
        controlName: 'proposalsState'
      },
    };
  }

  hasCreateProposal() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR_PROPOSAL]);
  }

  createProposal() {
    const dialogRef = this.dialog.open(NewProposalsComponent, {
      width: '75%',
      height: '60%',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(_ => {
      this.findProposals();
    });
  }

  retryProposal(idProposal: number) {
    this.loadingService.show();
    this.service.retryProposal(idProposal)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        const dialogRef = this.dialog.open(FdAlertComponent, {disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH, data: Messages.SENDING_RETRY});
        dialogRef.afterClosed().subscribe(_ => {
          this.findProposals();
        });
      }, () => {
        const dialogRef = this.dialog.open(FdAlertComponent, {disableClose: true, width: ModalDefinitions.DEFAULT_MODAL_WIDTH, data: Messages.SEARCH_ERROR});
        dialogRef.afterClosed().subscribe(_ => {
          this.findProposals();
        });
      });
  }

}




