import { Injectable } from '@angular/core';
import { ModalDefinitions, FdAlertComponent, AlertDialogData } from '../fd-form-components/fd-alert/fd-alert.component';
import { MatDialog } from '@angular/material';
import { Messages } from '../messages/messages';
import { ConfirmDialogData, FdConfirmComponent } from '../fd-form-components/fd-confirm/fd-confirm.component';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog,
              private loadingService: LoadingService) {
  }

  public openConfirmDialog(message: Messages, confirmCallbackFn?: () => any, cancelCallbackFn?: () => any): void {

    this.loadingService.hide();

    const dialogData: ConfirmDialogData = {
      description: message.description,
      title: message.title,
      confirmCallbackFn,
      cancelCallbackFn
    };

    const dialogRef = this.dialog.open(FdConfirmComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  public openDialogWithMessage(message: string, callbackFn?: () => any): void {

    this.loadingService.hide();

    const dialogData: AlertDialogData = {
      description: message,
      title: 'Atenção',
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(FdAlertComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  public openDialog(message: Messages, callbackFn?: () => any): void {

    this.loadingService.hide();

    const dialogData: AlertDialogData = {
      description: message.description,
      title: message.title,
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(FdAlertComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  public openDialogRunFunction(message: Messages, callbackFn?: () => any): void {

    this.loadingService.hide();

    const dialogData: AlertDialogData = {
      description: message.description,
      title: message.title,
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(FdAlertComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      callbackFn();
    });
  }

}
