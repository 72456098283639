/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/menu";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i11 from "@fortawesome/angular-fontawesome";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i14 from "./header.component";
import * as i15 from "../../../../shared/service/auth.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "mat-toolbar", [["class", "fd-accreditation-nav mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "h1", [["class", "fd-accreditation-nav__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Acesso Conciliadoras - ", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "nav", [["class", "fd-accreditation-nav__bar"], ["mat-tab-nav-bar", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, 0, 6, "button", [["aria-haspopup", "true"], ["class", "fd-accreditation-nav__user mat-menu-trigger"], ["mat-button", ""]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 1196032, null, 0, i6.MatMenuTrigger, [i7.Overlay, i1.ElementRef, i1.ViewContainerRef, i6.MAT_MENU_SCROLL_STRATEGY, [2, i6.MatMenu], [8, null], [2, i8.Directionality], i9.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(8, null, [" Ol\u00E1, ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "fa-icon", [["class", "user-circle ng-fa-icon"], ["icon", "user-circle"]], [[8, "innerHTML", 1]], null, null, i10.View_FaIconComponent_0, i10.RenderType_FaIconComponent)), i1.ɵdid(10, 573440, null, 0, i11.FaIconComponent, [i12.DomSanitizer, i11.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "fa-icon", [["class", "user-down ng-fa-icon"], ["icon", "sort-down"]], [[8, "innerHTML", 1]], null, null, i10.View_FaIconComponent_0, i10.RenderType_FaIconComponent)), i1.ɵdid(12, 573440, null, 0, i11.FaIconComponent, [i12.DomSanitizer, i11.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 11, "mat-menu", [], null, null, null, i13.View__MatMenu_0, i13.RenderType__MatMenu)), i1.ɵdid(14, 1294336, [["menu", 4]], 3, i6._MatMenu, [i1.ElementRef, i1.NgZone, i6.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 2, { _allItems: 1 }), i1.ɵqud(603979776, 3, { items: 1 }), i1.ɵqud(603979776, 4, { lazyContent: 0 }), i1.ɵprd(2048, null, i6.MatMenu, null, [i6._MatMenu]), i1.ɵprd(2048, null, i6.MAT_MENU_PANEL, null, [i6.MatMenu]), (_l()(), i1.ɵeld(20, 0, null, 0, 4, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 21)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.logout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i13.View_MatMenuItem_0, i13.RenderType_MatMenuItem)), i1.ɵdid(21, 180224, [[2, 4], [3, 4]], 0, i6.MatMenuItem, [i1.ElementRef, i5.DOCUMENT, i9.FocusMonitor, [2, i6.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵeld(22, 0, null, 0, 1, "fa-icon", [["class", "sign-out-alt ng-fa-icon"], ["icon", "sign-out-alt"]], [[8, "innerHTML", 1]], null, null, i10.View_FaIconComponent_0, i10.RenderType_FaIconComponent)), i1.ɵdid(23, 573440, null, 0, i11.FaIconComponent, [i12.DomSanitizer, i11.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵted(-1, 0, [" Sair "]))], function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 14); _ck(_v, 7, 0, currVal_4); var currVal_7 = "user-circle"; _ck(_v, 10, 0, currVal_7); var currVal_9 = "sort-down"; _ck(_v, 12, 0, currVal_9); _ck(_v, 14, 0); var currVal_17 = "sign-out-alt"; _ck(_v, 23, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.conciliatorName; _ck(_v, 4, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 7).menuOpen || null); _ck(_v, 6, 0, currVal_3); var currVal_5 = _co.name; _ck(_v, 8, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 9, 0, currVal_6); var currVal_8 = i1.ɵnov(_v, 12).renderedIconHTML; _ck(_v, 11, 0, currVal_8); var currVal_10 = i1.ɵnov(_v, 21).role; var currVal_11 = i1.ɵnov(_v, 21)._highlighted; var currVal_12 = i1.ɵnov(_v, 21)._triggersSubmenu; var currVal_13 = i1.ɵnov(_v, 21)._getTabIndex(); var currVal_14 = i1.ɵnov(_v, 21).disabled.toString(); var currVal_15 = (i1.ɵnov(_v, 21).disabled || null); _ck(_v, 20, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = i1.ɵnov(_v, 23).renderedIconHTML; _ck(_v, 22, 0, currVal_16); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i14.HeaderComponent, [i15.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("header", i14.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
