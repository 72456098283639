import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, LowerCasePipe, UpperCasePipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import {FdButtonComponent} from './fd-button/fd-button.component';
import {FdSelectComponent, FdSelectConfig} from './fd-select/fd-select.component';
import {FdInputComponent, FdInputConfig} from './fd-input/fd-input.component';
import {FdAlertComponent} from './fd-alert/fd-alert.component';
import {faDownload, faEdit, faExclamationTriangle, faFile, faUpload} from '@fortawesome/free-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FdLabelComponent, FdLabelConfig} from './fd-label/fd-label.component';
import {TextMaskModule} from 'angular2-text-mask';
import {FdFilesComponent} from './fd-files/fd-files.component';
import {FdCurrencyInputComponent, FdCurrencyInputConfig} from './fd-currency-input/fd-currency-input.component';
import {NgxCurrencyModule} from 'ngx-currency';
import {FdCardLinkComponent} from './fd-card-link/fd-card-link.component';
import {FdConfirmComponent} from './fd-confirm/fd-confirm.component';
import {FdRadioButtonComponent, FdRadioButtonConfig} from './fd-radio-button/fd-radio-button.component';
import {FdCheckboxComponent} from './fd-checkbox/fd-checkbox.component';
import {FdDatepickerComponent, FdDatepickerConfig} from './fd-datepicker/fd-datepicker.component';
import {FD_DATE_FORMATS, FdDateAdapter} from './fd-datepicker/date-adapter';
import {FdOptionPickerComponent, FdOptionPickerConfig} from './fd-option-picker/fd-option-picker.component';
import {OptionPickerModalComponent} from './fd-option-picker/option-picker-modal/option-picker-modal.component';
import { FdInstitutionComponent } from './fd-institution/fd-institution.component';
import { FdServiceContractComponent } from './fd-service-contract/fd-service-contract.component';
import { FdBankComponent } from './fd-bank/fd-bank.component';


export type FdFieldConfig =
  FdInputConfig | FdSelectConfig | FdCurrencyInputConfig | FdRadioButtonConfig | FdLabelConfig | FdDatepickerConfig | FdOptionPickerConfig;

export interface FdFieldConfigs {
  [key: string]: FdFieldConfig;
}

@NgModule({
  declarations: [
    FdButtonComponent,
    FdSelectComponent,
    FdInputComponent,
    FdAlertComponent,
    FdLabelComponent,
    FdFilesComponent,
    FdCurrencyInputComponent,
    FdCardLinkComponent,
    FdConfirmComponent,
    FdRadioButtonComponent,
    FdCheckboxComponent,
    FdDatepickerComponent,
    FdOptionPickerComponent,
    OptionPickerModalComponent,
    FdInstitutionComponent,
    FdServiceContractComponent,
    FdBankComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatSelectInfiniteScrollModule,
    MatPaginatorModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    NgxCurrencyModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTooltipModule
  ],
  exports: [
    FdButtonComponent,
    FdSelectComponent,
    FdInputComponent,
    FdAlertComponent,
    FdLabelComponent,
    FdFilesComponent,
    FdCurrencyInputComponent,
    FdCardLinkComponent,
    FdRadioButtonComponent,
    FdCheckboxComponent,
    FdDatepickerComponent,
    FdOptionPickerComponent,
    FdInstitutionComponent,
    FdServiceContractComponent,
    FdBankComponent
  ],
  entryComponents: [
    FdAlertComponent,
    FdConfirmComponent,
    OptionPickerModalComponent
  ],
  providers: [
    CurrencyPipe,
    UpperCasePipe,
    LowerCasePipe,
    {
      provide: DateAdapter, useClass: FdDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: FD_DATE_FORMATS
    }
  ]
})

export class FdFormComponentsModule {
  constructor() {
    library.add(faExclamationTriangle, faDownload, faUpload, faEdit, faFile);
  }

}
