import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSortModule, MatTabsModule, MatCheckboxModule} from '@angular/material';
import {MatPaginatorModule} from '@angular/material/paginator';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faMoneyCheckAlt,
  faPlusCircle,
  faTrash,
  faToggleOn,
  faToggleOff,
  faUserLock,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { UserComponent } from './user.component';
import { UserService } from './services/user.service';
import { PhonePipe } from '../shared/pipes/phone.pipe';
import { CpfCnpjPipe } from '../shared/pipes/cpf-cnpj.pipe';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import {FormUserComponent} from './components/form-user/form-user.component';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';


@NgModule({
  declarations: [
    ListUsersComponent,
    UserComponent,
    UserDetailsComponent,
    FormUserComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule
  ],
  entryComponents: [
    UserDetailsComponent,
    FormUserComponent
  ],
  providers: [
    UserService,
    PhonePipe,
    CpfCnpjPipe
  ],
})
export class UserModule {
  constructor() {
    library.add(faEdit, faUserLock, faMoneyCheckAlt, faPlusCircle, faTrash, faToggleOn, faToggleOff, faDownload);
  }
}

