export class AuthServiceModel {

    nome: string;
    sessionToken: string;
    fgBloqueado: string;
    roles: string[];
    userType: string;
    conciliatorId: number;
    conciliatorName: string;
}
