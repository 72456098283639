import {Component, Inject, OnInit} from '@angular/core';
import {finalize, map} from 'rxjs/operators';
import {FdSelectConfig, Item} from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FdFieldConfigs} from 'src/app/shared/fd-form-components/fd-form-components.module';
import {UserService} from '../../services/user.service';
import {DatePipe} from '@angular/common';
import {HierarchyService} from 'src/app/shared/service/hierarchy.service';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {DialogService} from 'src/app/shared/service/dialog.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {cpfMask} from 'src/app/shared/masks/document-masks';
import {PhoneMask} from 'src/app/shared/masks/phone-mask';
import {EmailMask} from 'src/app/shared/masks/email-mask';
import {Messages} from 'src/app/shared/messages/messages';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from 'src/app/shared/service/error.service';
import {LoginService} from 'src/app/login/services/login.service';
import {AuthService} from 'src/app/shared/service/auth.service';
import {CpfValidator} from 'src/app/shared/validators/cpf-validator';
import {UserModel} from '../../models/user.model';
import {ProfileModel} from '../../models/profile.model';

@Component({
  selector: 'app-form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})
export class FormUserComponent implements OnInit {

  private readonly CPF_REGEX = /^[0-9]{11}$/;
  private readonly CNPJ_REGEX = /^[0-9]{14}$/;

  existingUser = false;
  formGroup: FormGroup;
  update = false;
  fields: FdFieldConfigs;

  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private authService: AuthService,
    private errorService: ErrorService,
    private userEmailService: LoginService,
    private hierarchyService: HierarchyService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<FormUserComponent>,
    @Inject(MAT_DIALOG_DATA) public document: string,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    if (this.document) {
      this.loadingService.show();
      this.userService.userByDocument(this.document)
        .pipe(finalize( () => this.loadingService.hide()))
        .subscribe(item => {
            this.update = true;
            this.formGroup.controls.cpfCnpj.setValue(item.cpfCnpj);
            this.formGroup.controls.cpfCnpj.disable();
            this.formGroup.controls.name.setValue(item.name);
            this.formGroup.controls.name.disable();
            this.formGroup.controls.mobileNumber.setValue(item.mobileNumber);
            this.formGroup.controls.email.setValue(item.email);
            this.formGroup.controls.userProfile.setValue(item.profileId);
          }, error => console.error(error));
    }
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      cpfCnpj: ['', [Validators.required, CpfValidator]],
      mobileNumber: ['', Validators.required],
      email: ['', Validators.required],
      userProfile: ['', Validators.required],
    });
  }

  returnOnlyDigits(value: string) {
    if (value) {
      return value.replace(/[^0-9]/g, '');
    }
  }

  cpfCnpjChange(document: string) {
    if ((this.CPF_REGEX.test(document) || this.CNPJ_REGEX.test(document)) && this.formGroup.controls.cpfCnpj.valid) {
      this.loadingService.show();
      this.userService.userByDocument(document)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(userInformation => {
          this.dialogService.openDialog(Messages.USER_ALREADY_EXISTS);
          this.existingUser = true;
          this.formGroup.controls.cpfCnpj.setValue('');
          return;
        }, (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.existingUser = false;
            return;
          }
          this.errorService.handleXHRError(err, Messages.GENERAL_ERROR);
        });
    }
  }


  saveOrUpdate(): void {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if (this.existingUser) {
      this.dialogService.openDialog(Messages.USER_ALREADY_EXISTS);
      return;
    }

    const data: UserModel = {
      cpfCnpj: this.returnOnlyDigits(this.formGroup.controls.cpfCnpj.value),
      email: this.formGroup.value.email,
      name: this.formGroup.controls.name.value,
      mobileNumber: this.returnOnlyDigits(this.formGroup.value.mobileNumber),
      profileId: this.formGroup.value.userProfile
    };

    this.loadingService.show();
    if (this.update) {
      this.userService.updateUser(data)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(res => this.dialogService.openDialog(Messages.SAVE_SUCCESS, () => this.close())
          , error => {
            this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
          });
    } else {
      this.userService.createUser(data)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(res => this.dialogService.openDialog(Messages.EDIT_SUCCESS, () => this.close())
          , error => {
            this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
          });
    }
  }

  close() {
    this.dialogRef.close();
  }

  loadProfiles() {
    (this.fields.userProfile as FdSelectConfig).items = [];
    this.userService.profiles().pipe(map(p => this.mapToProfileModel(p.conteudo))).subscribe(data => {
      (this.fields.userProfile as FdSelectConfig).items.push(...data);
    });
  }

  private mapToProfileModel(response: ProfileModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
      .map(value => ({
        value: value.id,
        label: value.description,
      }));
  }

  createFields(): FdFieldConfigs {
    return {
      name: {
        label: 'Nome',
        controlName: 'name',
        messages: {
          required: 'Informe um nome'
        }
      },
      email: {
        label: 'E-mail',
        mask: EmailMask,
        controlName: 'email',
        messages: {
          required: 'Informe um e-mail',
          invalid: 'e-mail inválido'
        }
      },
      mobileNumber: {
        label: 'Telefone',
        mask: PhoneMask,
        controlName: 'mobileNumber',
        messages: {
          required: 'Informe um telefone',
          invalid: 'telefone inválido'
        }
      },
      cpfCnpj: {
        label: 'CPF',
        mask: cpfMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF',
          invalidCpf: 'CPF inválido'
        }
      },
      userProfile: {
        label: 'Perfil',
        items: [],
        controlName: 'userProfile',
        messages: {
          required: 'Informe um perfil'
        }
      },
    };
  }

}

