import { Component, OnInit, Input, Output, EventEmitter, Attribute } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'fd-button',
  templateUrl: './fd-button.component.html',
  styleUrls: ['./fd-button.component.scss']
})
export class FdButtonComponent implements OnInit {

  primary: boolean;
  secondary: boolean;
  tertiary: boolean;
  smallButton: boolean;
  fatButton: boolean;
  primaryLarge: boolean;
  primaryMedium: boolean;
  secondaryLarge: boolean;
  export:boolean

  @Input()
  content: string;

  @Input()
  disabled: boolean;

  @Output() trigger = new EventEmitter<void>();

  safeContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer,
              @Attribute('fdExport') exportTo: string,
              @Attribute('fdSecondary') secondary: string,
              @Attribute('fdTertiary') tertiary: string,
              @Attribute('fdSmallButton') smallButton: string,
              @Attribute('fdFatButton') fatButton: string,
              @Attribute('fdPrimaryLarge') primaryLarge: string,
              @Attribute('fdPrimaryMedium') primaryMedium: string,
              @Attribute('fdSecondaryLarge') secondaryLarge: string) {

    this.export = exportTo === '';
    this.secondary = secondary === '';
    this.smallButton = smallButton === '';
    this.tertiary = tertiary === '';
    this.primaryLarge = primaryLarge === '';
    this.fatButton = fatButton === '';
    this.primaryMedium = primaryMedium === '';
    this.secondaryLarge = secondaryLarge === '';
    this.primary = !this.secondary && !this.tertiary && !this.primaryMedium && !this.primaryLarge && !this.secondaryLarge && !this.export;
  }

  ngOnInit() {
    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  public handleTrigger(event: Event) {
    this.trigger.emit();
    event.stopPropagation();
  }

}
