import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBars, faTools, faSortDown, faUserCircle, faQuestion, faSignOutAlt, faChartBar, faDollarSign, faTags, faSitemap, faUsers, faUserTie, faCog, faTasks, faMoneyCheck, faClipboard, faClipboardCheck, faUserCog, faUsersCog, faSearchDollar
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { MatToolbarModule, MatMenuModule, MatSidenavModule, MatAccordion, MatExpansionModule } from '@angular/material';
import { SideNavComponent } from './layout/components/side-nav/side-nav.component';
import { HeaderComponent } from './layout/components/header/header.component';


@NgModule({
  declarations: [
    HeaderComponent,
    SideNavComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatExpansionModule
  ],
  providers: [],
  exports: [
    HeaderComponent,
    SideNavComponent
  ]
})
export class CoreModule {
  constructor() {
    library.add(
      faUsers, faBars, faTools, faSortDown, faUserCircle,
      faCog, faMoneyCheck, faClipboardCheck,
      faUsersCog, faSearchDollar,
      faQuestion, faSignOutAlt, faChartBar, faDollarSign,
      faTags, faSitemap, faUserTie, faTasks
    );
  }

}
