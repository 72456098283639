import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';
import { SideNavItems } from 'src/app/core/layout/components/side-nav/side-nav.items';
import { Messages } from '../messages/messages';
import * as i0 from "@angular/core";
import * as i1 from "../service/auth.service";
import * as i2 from "../service/dialog.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
export class BackofficeGuard {
    constructor(authService, dialogService, location, router) {
        this.authService = authService;
        this.dialogService = dialogService;
        this.location = location;
        this.router = router;
    }
    canActivate(currentRoute) {
        return of(currentRoute)
            .pipe(withLatestFrom())
            // mapeia a rota atual, o passo atual e a proposta para as seguintes variáveis:
            .pipe(map(([route]) => {
            if (!route || !route.routeConfig || !route.routeConfig.path) {
                return false;
            }
            if (!this.authService.isLoggedIn()) {
                this.router.navigate(['/login']);
            }
            const currentRoute = `/${route.routeConfig.path}`;
            const userRoles = this.authService.getUserRoles();
            if (!userRoles) {
                return false;
            }
            let relatedMenuItem = SideNavItems.filter(item => item.link === currentRoute);
            if (!relatedMenuItem || !relatedMenuItem.length) {
                relatedMenuItem = SideNavItems.filter(item => item.options && !!item.options.filter(x => x.link === currentRoute).length);
                if (!relatedMenuItem || !relatedMenuItem.length) {
                    return false;
                }
            }
            const menuItemRoles = relatedMenuItem[0].allowedRoles;
            if (!menuItemRoles || !menuItemRoles.length) {
                return false;
            }
            const canActivate = this.authService.isThereUserRoles();
            if (!canActivate) {
                this.dialogService.openDialog(Messages.ACCESS_PERMISSION_DENIED, () => this.location.back());
            }
            return canActivate;
        }));
    }
}
BackofficeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackofficeGuard_Factory() { return new BackofficeGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.DialogService), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.Router)); }, token: BackofficeGuard, providedIn: "root" });
