
export function PhoneMask(userInput: string): (string | RegExp)[] {
  const numbers = (userInput || '').replace(/\D/g, '');
  if (numbers.length <= 10) {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  } else {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
}



export function PhoneMaskZeroLeft(userInput: string): (string | RegExp)[] {
  const numbers = (userInput || '').replace(/\D/g, '');
  if (numbers.length <= 10) {
    if (numbers[0] && numbers[0] === '0') {
      return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
    }
    return ['(', /[0-9]/, /[0-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  } else {
    if (numbers[0] && numbers[0] === '0') {
      return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
    }
    return ['(', /[0-9]/, /[0-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
}
