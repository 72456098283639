import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule, OnDestroy} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FdFormComponentsModule} from './shared/fd-form-components/fd-form-components.module';
import {ProposalModule} from './proposal/proposal.module';
import {CoreComponent} from './core/core.component';
import {CoreModule} from './core/core.module';
import {LoginModule} from './login/login.module';
import {ErrorInterceptor} from './shared/helpers/error.interceptor';
import {JwtInterceptor} from './shared/helpers/jwt.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ExportExcelService} from './shared/service/export-excel.service';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {LoadingService} from './shared/service/loading.service';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faBan, faBars, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import ptBr from '@angular/common/locales/pt';
import {BackofficeGuard} from './shared/guards/backoffice.guard';
import {CpfCnpjPipe} from './shared/pipes/cpf-cnpj.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getPortuguesePaginatorIntl} from '../../intl/pt-BR/paginator-intl';
import {UserModule} from './user/user.module';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    CoreComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FdFormComponentsModule,
    ProposalModule,
    UserModule,
    CoreModule,
    LoginModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    BackofficeGuard,
    CpfCnpjPipe,
    ExportExcelService,
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl()},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-PT' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnDestroy {
  alive = true;

  constructor(
    private spinner: NgxSpinnerService,
    private loadingService: LoadingService
  ) {
    this.loadingService.getLoadingStatusObservable().subscribe(isActive => {
      if (isActive) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    library.add(faPlusCircle, faTimes, faBars, faBan);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
