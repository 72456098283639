import {MatPaginatorIntl} from '@angular/material/paginator';

export function getPortuguesePaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Itens por página:';
  paginatorIntl.nextPageLabel = 'Volgende pagina';
  paginatorIntl.previousPageLabel = 'Vorige pagina';

  return paginatorIntl;
}
